import { format } from "date-fns";

import { BlueprintOperationType } from "./Blueprints";
import { Integration, APICategory, OrganizationCategoryIntegration } from "./Entities";

// TODO: these helper functions are common across FE repos can prob refactor and centralize into merge-javascript-shared
export const displayNameForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HRIS";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "MKTG";
    case APICategory.filestorage:
      return "FileStorage";
    case APICategory.datawarehouse:
      return "Data Warehouse";
  }
};

export const fullDisplayNameForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "Applicant Tracking Systems (ATS)";
    case APICategory.hris:
      return "Human Resource Information Systems (HRIS)";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "Customer Relationship Management (CRM)";
    case APICategory.mktg:
      return "Marketing Automation (MKTG)";
    case APICategory.filestorage:
      return "File Storage";
    case APICategory.datawarehouse:
      return "Data Warehouse";
  }
};

export const featherIconForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.hris:
      return "users";
    case APICategory.ats:
      return "user-plus";
    case APICategory.accounting:
      return "dollar-sign";
    case APICategory.ticketing:
      return "list";
    case APICategory.crm:
      return "layout";
    case APICategory.mktg:
      return "";
    case APICategory.filestorage:
      return "";
    case APICategory.datawarehouse:
      return "database";
  }
};

export const apiCategoryFromString = (categoryName: string): APICategory | null => {
  switch (categoryName) {
    case APICategory.hris:
      return APICategory.hris;
    case APICategory.ats:
      return APICategory.ats;
    case APICategory.accounting:
      return APICategory.accounting;
    case APICategory.ticketing:
      return APICategory.ticketing;
    case APICategory.crm:
      return APICategory.crm;
    case APICategory.mktg:
      return APICategory.mktg;
    case APICategory.filestorage:
      return APICategory.filestorage;
    case APICategory.datawarehouse:
      return APICategory.datawarehouse;
    default:
      return null;
  }
};

export function getAPIRequestPath(endpoint: string, integration: Integration) {
  const path = endpoint.substring(integration.base_api_url.length, endpoint.length);
  return path;
}

export function countEnabledIntegrations(integrations: Array<OrganizationCategoryIntegration>) {
  return integrations.filter((orgIntegration) => orgIntegration.is_enabled).length;
}

export const BLUEPRINT_OPERATION_TYPE_LIST = [
  BlueprintOperationType.FETCH,
  BlueprintOperationType.CREATE,
  BlueprintOperationType.UPSERT,
  BlueprintOperationType.EDIT,
  BlueprintOperationType.DELETE,
  BlueprintOperationType.VALIDATE,
  BlueprintOperationType.GET_AVAILABLE_CUSTOM_FIELDS,
  BlueprintOperationType.FUNCTIONAL,
  BlueprintOperationType.ENDPOINT_MODIFICATION,
  BlueprintOperationType.CUSTOM_OBJECT_CLASS_GENERATION,
  BlueprintOperationType.FETCH_FILTER_OPTIONS,
  BlueprintOperationType.WEBHOOK_SETUP,
  BlueprintOperationType.PROXY,
  BlueprintOperationType.LIVE_REQUEST,
  BlueprintOperationType.ACCOUNT_DELETION,
  BlueprintOperationType.LIVE_SEARCH,
  BlueprintOperationType.LIVE_RESYNC,
];

export const blueprintWriteOperations = [
  BlueprintOperationType.CREATE,
  BlueprintOperationType.UPSERT,
  BlueprintOperationType.EDIT,
  BlueprintOperationType.DELETE,
];

export const blueprintProxyOperations = [
  BlueprintOperationType.PROXY,
  BlueprintOperationType.LIVE_REQUEST,
  BlueprintOperationType.LIVE_SEARCH,
  BlueprintOperationType.LIVE_RESYNC,
];

export const blueprintUpdateExistingModelOperations = [
  BlueprintOperationType.EDIT,
  BlueprintOperationType.DELETE,
];

export const MAPPING_TEST_OPERATION_TYPE_LIST = [BlueprintOperationType.FETCH];

/**
 * Return a string of a human-readable timestamp, e.g. "Jun 26, 05:24 PM".
 *
 * Note that year is omitted because it is assumed to be a recent timestamp.
 */
export const formatRecentTimestamp = (dateIsoString?: string): string | null => {
  if (!dateIsoString) {
    return null;
  }
  return format(new Date(dateIsoString), "MMM dd, hh:mm a");
};
