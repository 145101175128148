import {
  BlueprintParameterValue,
  BlueprintParameterValueType,
  BlueprintStep,
  BlueprintStepType,
  BlueprintVariableSteps,
} from "../../../../models/Blueprints";

/**
 * Helper function for getMappedVariablesForParameterValue
 */
const getMappedVariablesForChildrenParameterValues = (
  foundVariables: Set<string>,
  values: BlueprintParameterValue[]
): Set<string> => {
  Object.values(values).forEach((childParameterValue) => {
    getMappedVariablesForParameterValue(childParameterValue).forEach((foundVariableInChild) =>
      foundVariables.add(foundVariableInChild)
    );
  });
  return foundVariables;
};

/**
 * Extracts the mapped variables in parameter values in BlueprintVariableSteps
 * (ie.: Data Transform, Add to Array, Add to Dictionary)
 */
const getMappedVariablesForParameterValue = (value: BlueprintParameterValue): Set<string> => {
  let foundVariables: Set<string> = new Set();
  if (value?.hasOwnProperty("value_type")) {
    switch (value["value_type"]) {
      case BlueprintParameterValueType.variable: {
        if (value?.hasOwnProperty("key")) {
          foundVariables.add(value["key"]);
        }
        break;
      }
      case BlueprintParameterValueType.nestedParameterValues: {
        if (value?.hasOwnProperty("nested_parameter_values")) {
          foundVariables = getMappedVariablesForChildrenParameterValues(
            foundVariables,
            Object.values(value["nested_parameter_values"])
          );
        }
        break;
      }
      case BlueprintParameterValueType.customObject: {
        if (value?.hasOwnProperty("object_value")) {
          foundVariables = getMappedVariablesForChildrenParameterValues(
            foundVariables,
            Object.values(value["object_value"])
          );
        }
        break;
      }
      case BlueprintParameterValueType.customArray: {
        if (value?.hasOwnProperty("array_values")) {
          foundVariables = getMappedVariablesForChildrenParameterValues(
            foundVariables,
            Object.values(value["array_values"])
          );
        }
        break;
      }
      case BlueprintParameterValueType.procedureArray: {
        if (value?.hasOwnProperty("procedure_array")) {
          (value["procedure_array"] ?? []).forEach((procedure) => {
            foundVariables = getMappedVariablesForChildrenParameterValues(
              foundVariables,
              Object.values(procedure.parameter_values).filter(
                (childParameterValue): childParameterValue is BlueprintParameterValue =>
                  !!childParameterValue
              )
            );
          });
        }
        break;
      }
      default:
        break;
    }
  }
  return foundVariables;
};

/**
 * Returns a list of variables that a step can alter
 * We'll only look at BlueprintVariableSteps - aka steps that set or modify a variable
 */
export const getMappedVariablesForStep = (step: BlueprintStep): Set<string> | null => {
  if (BlueprintVariableSteps.includes(step.template.step_type as BlueprintStepType)) {
    let variables: Set<string> = new Set();
    // Process SET_VARIABLE steps
    if ((step.template.step_type as BlueprintStepType) == BlueprintStepType.SetVariable) {
      if (step.parameter_values.key?.value_type === "CONSTANT") {
        variables.add(step.parameter_values.key.constant);
      }
    }
    // Process other variable transformation steps
    else {
      Object.values(step.parameter_values).forEach((parameterValue) => {
        const nestedVariables = getMappedVariablesForParameterValue(parameterValue);
        nestedVariables.forEach((nestedVariable) => variables.add(nestedVariable));
      });
    }
    return variables;
  }
  return null;
};
