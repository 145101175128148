import { isEqual } from "lodash";
import { useReducer, useCallback, Reducer, useMemo } from "react";
import {
  ErrorPath,
  MappingTestAndVersions,
  MappingTestBlock,
  MappingTestErrorInfo,
  MappingTestErrorResponse,
  MappingTestVersion,
  MappingTestCommonModelExpectedMappings,
  MappingTestRequestMock,
  MappingTestExpectedMappings,
} from "../../../models/MappingTests";
import { showSuccessToast, showErrorToast } from "../../shared/Toasts";
import {
  stageMappingTest,
  unstageMappingTest,
  saveMappingTestDraft,
  deleteRequestMock,
} from "../utils/MappingTestFetchUtils";
import cloneDeep from "lodash/cloneDeep";
import {
  NextComponentVersionState,
  ParentIntegrationComponentModelAndVersions,
} from "../../integrations/versioned-components/types";
import { v4 as uuidv4 } from "uuid";

enum MappingTestReducerActions {
  SAVE_DRAFT = "SAVE_DRAFT",
  STAGE = "STAGE",
  UNSTAGE = "UNSTAGE",
  MARK_MAPPING_TEST_AS_SAVING = "MARK_MAPPING_TEST_AS_SAVING",
  MARK_MAPPING_TEST_AS_SAVED = "MARK_MAPPING_TEST_AS_SAVED",
  MARK_MAPPING_TEST_AS_SAVED_UNSUCCESSFULLY = "MARK_MAPPING_TEST_AS_SAVED_UNSUCCESSFULLY",
  UNDO = "UNDO",
  REDO = "REDO",
  UPDATE_MAPPING_TEST_FIELD = "UPDATE_MAPPING_TEST_FIELD",
  UPDATE_COMMON_MODEL_EXPECTED_MAPPINGS = "UPDATE_COMMON_MODEL_EXPECTED_MAPPINGS",
  UPDATE_COMMON_MODEL_EXPECTED_MAPPINGS_FOR_BLOCK = "UPDATE_COMMON_MODEL_EXPECTED_MAPPINGS_FOR_BLOCK",
  UPDATE_COMMON_MODEL_COUNT_ASSERTION = "UPDATE_COMMON_MODEL_COUNT_ASSERTION",
  UPDATE_COMMON_MODEL_COUNT_ASSERTION_FOR_BLOCK = "UPDATE_COMMON_MODEL_COUNT_ASSERTION_FOR_BLOCK",
  UPDATE_MAPPING_TEST_REQUEST_BODY = "UPDATE_MAPPING_TEST_REQUEST_BODY",
  RESET_MAPPING_TEST_REQUEST_BODY = "RESET_MAPPING_TEST_REQUEST_BODY",
  UPDATE_MAPPING_TEST_BP_METADATA = "UPDATE_MAPPING_TEST_BP_METADATA",
  EDIT_REQUEST_MOCK = "EDIT_REQUEST_MOCK",
  ADD_REQUEST_MOCK = "ADD_REQUEST_MOCK",
  PASTE_REQUEST_MOCK = "PASTE_REQUEST_MOCK",
  DELETE_REQUEST_MOCK = "DELETE_REQUEST_MOCK",
  ADD_MAPPING_TEST_BLOCK = "ADD_MAPPING_TEST_BLOCK",
  PASTE_MAPPING_TEST_BLOCK = "PASTE_MAPPING_TEST_BLOCK",
  REMOVE_MAPPING_TEST_BLOCK = "REMOVE_MAPPING_TEST_BLOCK",
  ADD_BLUEPRINT_TO_MAPPING_TEST_BLOCK = "ADD_BLUEPRINT_TO_MAPPING_TEST_BLOCK",
  REMOVE_BLUEPRINT_FROM_MAPPING_TEST_BLOCK = "REMOVE_BLUEPRINT_FROM_MAPPING_TEST_BLOCK",
  ADD_REQUEST_MOCK_TO_MAPPING_TEST_BLOCK = "ADD_REQUEST_MOCK_TO_MAPPING_TEST_BLOCK",
  REMOVE_REQUEST_MOCK_FROM_MAPPING_TEST_BLOCK = "REMOVE_REQUEST_MOCK_FROM_MAPPING_TEST_BLOCK",
  SET_OVERRIDE_LAST_RUN_AT_FOR_BLUEPRINT_IN_TEST_BLOCK = "SET_OVERRIDE_LAST_RUN_AT_FOR_BLUEPRINT_IN_TEST_BLOCK",
  SET_DISABLE_FILTER_BY_DATE_FOR_BLUEPRINT_IN_TEST_BLOCK = "SET_DISABLE_FILTER_BY_DATE_FOR_BLUEPRINT_IN_TEST_BLOCK",
  UPDATE_MAPPING_TEST_FREEZE_TIME = "UPDATE_MAPPING_TEST_FREEZE_TIME",
  UPDATE_RELATION_NAME = "UPDATE_RELATION_NAME",
}

type MappingTestReducerStateInstance = {
  mappingTestState: NextComponentVersionState;
  mappingTestVersionUnderConstruction: MappingTestVersion;
};

type State = {
  past: Array<MappingTestReducerStateInstance>;
  present: MappingTestReducerStateInstance;
  future: Array<MappingTestReducerStateInstance>;
  lastSavedMappingTest: MappingTestReducerStateInstance;
  isSaving: boolean;
};

type ActionBaseProps = {
  type: MappingTestReducerActions;
  newPresent?: MappingTestReducerStateInstance;
  initialPresent?: MappingTestReducerStateInstance;
  newFieldKey?: string;
  newFieldValue?: unknown;
  commonModelID?: string;
  count?: number;
  commonModelExpectedMappings?: MappingTestCommonModelExpectedMappings;
  mappingTestID?: string;
  apiRequestID?: string;
  apiRequestBody?: string;
  oldName?: string;
  newName?: string;
  blueprintID?: string;
  requestMock?: MappingTestRequestMock;
  mappingTestBlockID?: string;
  mappingTestBlock?: MappingTestBlock;
  errors?: MappingTestErrorInfo;
  overrideLastRunAtValue?: string | null;
  disableFilterByDateValue?: boolean;
  newDateTimeValue?: string;
  deletedRequestMockName?: string;
};

type Action = ActionBaseProps & {
  dispatch?: (props: ActionBaseProps) => void;
};
const reducer = (state: State, action: Action): State => {
  const { past, present, future } = state;

  const getBlockIndexByID = (mappingTestBlockID: string) => {
    const idx = present.mappingTestVersionUnderConstruction.mapping_test_blocks.findIndex(
      (element) => {
        return element.id === mappingTestBlockID;
      }
    );

    if (!idx && idx != 0)
      throw new Error(`Could not find mapping test block with id ${mappingTestBlockID}`);

    return idx;
  };

  const updateForNewPresent = (newPresent: MappingTestReducerStateInstance) => {
    if (newPresent === present) {
      return state;
    }
    if (present === undefined) {
      return { ...state, past: [], present: newPresent, future: [] };
    }
    return {
      ...state,
      past: [...past, present],
      present: newPresent,
      future: [],
    };
  };

  switch (action.type) {
    case MappingTestReducerActions.UNDO: {
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);

      return {
        ...state,
        past: newPast,
        present: previous,
        future: [present, ...future],
      };
    }

    case MappingTestReducerActions.REDO: {
      const next = future[0];
      const newFuture = future.slice(1);

      return {
        ...state,
        past: [...past, present],
        present: next,
        future: newFuture,
      };
    }

    case MappingTestReducerActions.UPDATE_MAPPING_TEST_FIELD: {
      const { newFieldKey, newFieldValue } = action;
      if (!newFieldKey) {
        return state;
      }

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          [newFieldKey]: newFieldValue,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.UPDATE_RELATION_NAME: {
      const { commonModelID, mappingTestBlockID, oldName, newName } = action;

      if (!mappingTestBlockID || !commonModelID) {
        throw new Error();
      }

      const idx = getBlockIndexByID(mappingTestBlockID);
      const newMappingTestBlock = cloneDeep(
        present.mappingTestVersionUnderConstruction.mapping_test_blocks[idx]
      );

      const oldMappings = newMappingTestBlock["common_model_mappings"];

      const newMappings: MappingTestExpectedMappings = Object.fromEntries(
        Object.entries(oldMappings).map(([modelId, mappingSet]) => {
          if (modelId == commonModelID) {
            return [
              modelId,
              {
                ...mappingSet,
                individual_mappings: Object.fromEntries(
                  Object.entries(mappingSet.individual_mappings).map(([name, mapping]) => {
                    if (name == oldName) {
                      return [newName, mapping];
                    } else {
                      return [name, mapping];
                    }
                  })
                ),
              },
            ];
          }
          return [
            modelId,
            {
              ...mappingSet,
            },
          ];
        })
      );

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: [
            ...present.mappingTestVersionUnderConstruction.mapping_test_blocks.slice(0, idx),
            { ...newMappingTestBlock, common_model_mappings: { ...oldMappings, ...newMappings } },
            ...present.mappingTestVersionUnderConstruction.mapping_test_blocks.slice(idx + 1),
          ],
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.UPDATE_MAPPING_TEST_BP_METADATA: {
      const { blueprintID, newFieldValue } = action;
      if (!blueprintID) {
        return state;
      }

      if (!(typeof newFieldValue === "object") || newFieldValue == null) {
        throw new Error("BlueprintMetadata must be an object");
      }

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      newMappingTestBlocks.forEach((block) => {
        // Update ordered_blueprints_meta if it exists and the specific blueprintID is found
        if (Array.isArray(block["ordered_blueprints_meta"])) {
          block["ordered_blueprints_meta"].forEach((orderedBlueprintMeta) => {
            if (orderedBlueprintMeta[blueprintID]) {
              orderedBlueprintMeta[blueprintID].input_payload = newFieldValue;
            }
          });
        }
      });

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          blueprint_metadata: {
            ...present.mappingTestVersionUnderConstruction.blueprint_metadata,
            [blueprintID]: { input_payload: newFieldValue },
          },
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.UPDATE_COMMON_MODEL_EXPECTED_MAPPINGS: {
      const { commonModelID, commonModelExpectedMappings } = action;
      if (!commonModelID) {
        return state;
      }

      const {
        [commonModelID]: commonModelObject,
        ...otherExpectedMappings
      } = present.mappingTestVersionUnderConstruction.common_model_mappings;

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          common_model_mappings: {
            ...otherExpectedMappings,
            ...(commonModelExpectedMappings
              ? {
                  [commonModelID]: {
                    ...commonModelObject,
                    individual_mappings: commonModelExpectedMappings,
                  },
                }
              : undefined),
          },
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.UPDATE_COMMON_MODEL_EXPECTED_MAPPINGS_FOR_BLOCK: {
      const { commonModelID, commonModelExpectedMappings, mappingTestBlockID } = action;
      if (!commonModelID || !mappingTestBlockID) {
        return state;
      }

      const idx = getBlockIndexByID(mappingTestBlockID);

      const oldBlock = present.mappingTestVersionUnderConstruction.mapping_test_blocks[idx];

      const {
        [commonModelID]: commonModelObject,
        ...otherExpectedMappings
      } = oldBlock.common_model_mappings;

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: [
            ...present.mappingTestVersionUnderConstruction.mapping_test_blocks.slice(0, idx),
            {
              ...oldBlock,
              common_model_mappings: {
                ...otherExpectedMappings,
                ...(commonModelExpectedMappings
                  ? {
                      [commonModelID]: {
                        ...commonModelObject,
                        individual_mappings: commonModelExpectedMappings,
                      },
                    }
                  : undefined),
              },
            },
            ...present.mappingTestVersionUnderConstruction.mapping_test_blocks.slice(idx + 1),
          ],
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.SAVE_DRAFT: {
      const { dispatch, mappingTestID } = action;
      if (!dispatch || !mappingTestID) {
        throw new Error("Unexpected state");
      }

      saveMappingTestDraft({
        mappingTestID,
        mappingTestVersion: state.present.mappingTestVersionUnderConstruction,
        onResponse: (mappingTestAndVersions: MappingTestAndVersions) => {
          if (mappingTestAndVersions.next_version) {
            dispatch({
              type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVED,
              newPresent: {
                mappingTestVersionUnderConstruction: mappingTestAndVersions.next_version,
                mappingTestState: mappingTestAndVersions.next_version_state,
              },
            });

            showSuccessToast("Successfully saved!");
          } else {
            showErrorToast("Save returned null value. Something's up.");
          }
        },
        onError: (data: any) => {
          data.json().then((errorInfo: MappingTestErrorResponse) => {
            // TODO: https://app.asana.com/0/1204464925313817/1205112941599071
            // Move this formatting logic to the backend
            // Adding it here now for the purposes of shipping it fast
            const findErrorPathsAndDetails = (
              errors: any,
              currentPath: string = ""
            ): MappingTestErrorInfo => {
              let paths: MappingTestErrorInfo = [];

              for (let key in errors) {
                let newPath = currentPath ? `${currentPath}.${key}` : key;

                if (typeof errors[key] === "object") {
                  paths = paths.concat(findErrorPathsAndDetails(errors[key], newPath));
                } else {
                  paths.push({ path: newPath, message: errors[key] });
                }
              }

              return paths;
            };

            const replaceKeyPathsWithComponentNames = (
              errorPathAndMessage: ErrorPath
            ): ErrorPath => {
              const { path } = errorPathAndMessage;
              const keys = path.split(".");

              const requestIndex = parseInt(keys?.[2]);
              const requestField = keys?.[3];

              // Check if the offending component is a request mock
              if (keys.length >= 4 && keys[1] == "requests" && !isNaN(requestIndex)) {
                const request = present.mappingTestVersionUnderConstruction.requests[requestIndex];
                return {
                  path: `${request.name}`,
                  message: errorPathAndMessage.message,
                  field: requestField,
                };
              }
              return errorPathAndMessage;
            };

            const errorKeyPaths = findErrorPathsAndDetails(errorInfo);

            const errorsWithComponentNames = errorKeyPaths.map((errorInfo) =>
              replaceKeyPathsWithComponentNames(errorInfo)
            );

            dispatch({
              type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVED_UNSUCCESSFULLY,
              errors: errorsWithComponentNames,
            });

            const firstError = errorsWithComponentNames[0];
            showErrorToast(
              firstError
                ? `Error: ${firstError.message}, for component ${firstError.path}, ${firstError?.field}`
                : "Save Unsuccessful"
            );
          });
        },
      });

      return state;
    }

    case MappingTestReducerActions.STAGE: {
      const { dispatch, mappingTestID } = action;
      if (!dispatch || !mappingTestID) {
        throw new Error("Unexpected state");
      }

      stageMappingTest({
        mappingTestID,
        onResponse: (parentComponentModel: ParentIntegrationComponentModelAndVersions) => {
          dispatch({
            type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVED,
            newPresent: {
              ...state.present,
              mappingTestState: parentComponentModel.next_component_version_state,
            },
          });
          showSuccessToast("Staged! Mapping tests initiated. Monitor in Publish Module.");
        },
        onError: () => {
          dispatch({ type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVED_UNSUCCESSFULLY });
          showErrorToast("Staging unsuccessful.");
        },
      });

      return state;
    }

    case MappingTestReducerActions.UNSTAGE: {
      const { dispatch, mappingTestID } = action;
      if (!dispatch || !mappingTestID) {
        throw new Error("Unexpected state");
      }

      unstageMappingTest({
        mappingTestID,
        onResponse: (parentComponentModel: ParentIntegrationComponentModelAndVersions) => {
          dispatch({
            type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVED,
            newPresent: {
              ...state.present,
              mappingTestState: parentComponentModel.next_component_version_state,
            },
          });
          showSuccessToast("Unstaged! Mapping tests initiated. Monitor in Publish Module.");
        },
        onError: () => {
          dispatch({ type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVED_UNSUCCESSFULLY });
          showErrorToast("Unstaging unsuccessful.");
        },
      });

      return state;
    }
    case MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVING: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVED: {
      const { newPresent } = action;
      if (!newPresent) {
        throw new Error("Unexpected state");
      }

      return {
        ...state,
        present: newPresent,
        lastSavedMappingTest: newPresent,
        isSaving: false,
      };
    }

    case MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVED_UNSUCCESSFULLY: {
      const { errors } = action;

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          construction_errors: errors ?? [],
        },
      };

      return {
        ...state,
        present: newPresent,
        lastSavedMappingTest: newPresent,
        isSaving: false,
      };
    }

    case MappingTestReducerActions.UPDATE_COMMON_MODEL_COUNT_ASSERTION: {
      const { commonModelID, count } = action;
      if (!commonModelID || count === undefined) {
        return state;
      }

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          common_model_count_assertions: {
            ...present.mappingTestVersionUnderConstruction.common_model_count_assertions,
            [commonModelID]: count,
          },
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.UPDATE_COMMON_MODEL_COUNT_ASSERTION_FOR_BLOCK: {
      const { commonModelID, count, mappingTestBlockID } = action;
      if (!commonModelID || count === undefined || !mappingTestBlockID) {
        return state;
      }

      const idx = getBlockIndexByID(mappingTestBlockID);

      const oldBlock = present.mappingTestVersionUnderConstruction.mapping_test_blocks[idx];

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: [
            ...present.mappingTestVersionUnderConstruction.mapping_test_blocks.slice(0, idx),
            {
              ...oldBlock,
              common_model_count_assertions: {
                ...oldBlock.common_model_count_assertions,
                [commonModelID]: count,
              },
            },
            ...present.mappingTestVersionUnderConstruction.mapping_test_blocks.slice(idx + 1),
          ],
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.UPDATE_MAPPING_TEST_REQUEST_BODY: {
      const modifyRequest = (
        request: MappingTestRequestMock,
        newRequestBody: string
      ): MappingTestRequestMock => {
        if (!request.original_response_body) {
          request.original_response_body = request.response_body;
          request.response_body = newRequestBody;
        } else {
          request.response_body = newRequestBody;
        }
        request.edited_at = new Date().toISOString();
        return request;
      };

      const { apiRequestID, apiRequestBody } = action;
      if (!apiRequestID || apiRequestBody === undefined) {
        return state;
      }
      const newRequests = cloneDeep(present.mappingTestVersionUnderConstruction.requests);

      const idx = newRequests.findIndex((element) => {
        return element.id === apiRequestID;
      });

      newRequests[idx] = modifyRequest(newRequests[idx], apiRequestBody);

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          requests: newRequests,
        },
      };
      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.RESET_MAPPING_TEST_REQUEST_BODY: {
      const modifyRequest = (request: MappingTestRequestMock): MappingTestRequestMock => {
        if (request.original_response_body) {
          request.response_body = request.original_response_body;
          request.original_response_body = "";
          request.edited_at = null;
        }
        return request;
      };

      const { apiRequestID } = action;
      if (!apiRequestID) {
        return state;
      }

      const newRequests = cloneDeep(present.mappingTestVersionUnderConstruction.requests);

      const idx = newRequests.findIndex((element) => {
        return element.id === apiRequestID;
      });

      newRequests[idx] = modifyRequest(newRequests[idx]);

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          requests: newRequests,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.ADD_REQUEST_MOCK: {
      const { requestMock } = action;
      if (!requestMock) {
        return state;
      }

      const newRequestMock = { ...requestMock };

      const newRequests = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.requests),
        newRequestMock,
      ];

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          requests: newRequests,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.EDIT_REQUEST_MOCK: {
      const { requestMock } = action;
      if (!requestMock) {
        return state;
      }

      const newMockName = requestMock.name;

      const newRequests = [...cloneDeep(present.mappingTestVersionUnderConstruction.requests)];
      // Check for request mock name collisions
      const mocksWithNewName = newRequests.filter((mock) => mock.name == requestMock.name);

      if (mocksWithNewName.some((mock) => mock.name == newMockName && mock.id !== requestMock.id)) {
        showErrorToast(`Can not have multiple request mocks with the same name: ${newMockName}`);
        return state;
      }

      const idx = newRequests.findIndex((element) => {
        return element.id === requestMock.id;
      });

      const oldName = newRequests[idx].name;

      const nameHasChanged = oldName != requestMock.name;
      let newBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      // Update request mock name references if they exist
      if (nameHasChanged) {
        const replaceNameInMappingTestBlocks = (
          oldBlocks: Array<MappingTestBlock>,
          oldName: string,
          newName: string
        ): Array<MappingTestBlock> => {
          return oldBlocks.map((block) => {
            const updatedOrderedBlueprintsMeta = block["ordered_blueprints_meta"] || [];

            // Update ordered_blueprints_meta
            updatedOrderedBlueprintsMeta.forEach((orderedBpMeta) => {
              Object.values(orderedBpMeta).forEach(
                (bpMeta) =>
                  // Loop through the request_mocks array of each MappingTestBlockBlueprintsMeta in ordered_blueprints_meta
                  (bpMeta["request_mocks"] = bpMeta["request_mocks"].map((requestMockName) =>
                    requestMockName === oldName ? newName : requestMockName
                  ))
              );
            });

            // Return the updated MappingTestBlock with updated ordered_blueprints_meta
            return {
              ...block,
              ordered_blueprints_meta: updatedOrderedBlueprintsMeta,
            };
          });
        };

        newBlocks = replaceNameInMappingTestBlocks(newBlocks, oldName ?? "", newMockName ?? "");
      }

      newRequests[idx] = requestMock;

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          requests: newRequests,
          mapping_test_blocks: newBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.PASTE_REQUEST_MOCK: {
      const { requestMock } = action;
      if (!requestMock) {
        return state;
      }

      const copiedRequestMock = { ...requestMock };
      copiedRequestMock.name = "Copy of " + requestMock.name;
      copiedRequestMock.id = uuidv4();

      const newRequests = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.requests),
        copiedRequestMock,
      ];

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          requests: newRequests,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.DELETE_REQUEST_MOCK: {
      const { requestMock } = action;
      if (!requestMock) {
        return state;
      }

      deleteRequestMock({
        mockRequestID: requestMock.id,
        onResponse: () => {
          showSuccessToast("Request mock successfully deleted");
        },
        onError: () => {
          showErrorToast("Request mock could not be deleted");
        },
      });

      const newRequests = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.requests).filter(
          (mock) => mock.name != requestMock.name
        ),
      ];

      let newBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      // Remove request mock name references if they exist
      const removeRequestMockFromTestBlocks = (
        oldBlocks: Array<MappingTestBlock>,
        request: MappingTestRequestMock
      ): Array<MappingTestBlock> => {
        return oldBlocks.map((block) => {
          const updatedOrderedBlueprintsMeta = block["ordered_blueprints_meta"] || [];

          // Update ordered_blueprints_meta
          updatedOrderedBlueprintsMeta.forEach((orderedBpMeta) => {
            Object.values(orderedBpMeta).forEach(
              (bpMeta) =>
                // Loop through the request_mocks array of each MappingTestBlockBlueprintsMeta in ordered_blueprints_meta
                (bpMeta["request_mocks"] = bpMeta["request_mocks"].filter(
                  (requestMockName) => requestMockName !== request.name
                ))
            );
          });
          // Return the updated MappingTestBlock with updated ordered_blueprints_meta
          return {
            ...block,
            ordered_blueprints_meta: updatedOrderedBlueprintsMeta,
          };
        });
      };

      newBlocks = removeRequestMockFromTestBlocks(newBlocks, requestMock);

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          requests: newRequests,
          mapping_test_blocks: newBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.ADD_MAPPING_TEST_BLOCK: {
      const { mappingTestID } = action;
      if (!mappingTestID) {
        return state;
      }

      const oldBlocks = cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks);

      const latestBlock = oldBlocks.length > 0 ? oldBlocks[oldBlocks.length - 1] : null;

      const createEmptyMappingTestBlock = (): MappingTestBlock => {
        return {
          order: present.mappingTestVersionUnderConstruction?.mapping_test_blocks.length,
          blueprints_meta: {},
          ordered_blueprints_meta: [],
          id: uuidv4(),
          mapping_test_version_id: mappingTestID,
          common_model_count_assertions: latestBlock
            ? cloneDeep(latestBlock.common_model_count_assertions)
            : {},
          common_model_mappings: latestBlock ? cloneDeep(latestBlock.common_model_mappings) : {},
        };
      };

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
        createEmptyMappingTestBlock(),
      ];

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.PASTE_MAPPING_TEST_BLOCK: {
      const { mappingTestID, mappingTestBlock } = action;
      if (!mappingTestID || !mappingTestBlock) {
        return state;
      }

      const pasteMappingTestBlock = (mappingTestBlock: MappingTestBlock): MappingTestBlock => {
        return {
          order: present.mappingTestVersionUnderConstruction?.mapping_test_blocks.length,
          blueprints_meta: mappingTestBlock ? cloneDeep(mappingTestBlock.blueprints_meta) : {},
          ordered_blueprints_meta: mappingTestBlock.ordered_blueprints_meta
            ? cloneDeep(mappingTestBlock.ordered_blueprints_meta)
            : [],
          id: uuidv4(),
          mapping_test_version_id: mappingTestID,
          common_model_count_assertions: mappingTestBlock
            ? cloneDeep(mappingTestBlock.common_model_count_assertions)
            : {},
          common_model_mappings: mappingTestBlock
            ? cloneDeep(mappingTestBlock.common_model_mappings)
            : {},
        };
      };

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
        pasteMappingTestBlock(mappingTestBlock),
      ];

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.ADD_BLUEPRINT_TO_MAPPING_TEST_BLOCK: {
      const { mappingTestBlockID, blueprintID } = action;
      if (!(mappingTestBlockID && blueprintID)) {
        return state;
      }

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      const idx = newMappingTestBlocks.findIndex((element) => {
        return element.id === mappingTestBlockID;
      });

      if (idx === -1) {
        return state;
      }

      const blockToUpdate = newMappingTestBlocks[idx];

      // Logic for updating blocks with ordered_blueprints_meta: ensure ordered_blueprints_meta exists, check if bp id is already in any of the ordered entries, then add if not already present
      blockToUpdate["ordered_blueprints_meta"] = blockToUpdate["ordered_blueprints_meta"] || [];

      const orderedMetaContainsBlueprint = blockToUpdate["ordered_blueprints_meta"].some(
        (meta) => blueprintID in meta
      );

      if (!orderedMetaContainsBlueprint) {
        blockToUpdate["ordered_blueprints_meta"].push({
          [blueprintID]: {
            request_mocks: [],
          },
        });
      }

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.ADD_REQUEST_MOCK_TO_MAPPING_TEST_BLOCK: {
      const { mappingTestBlockID, blueprintID, requestMock } = action;
      if (!(mappingTestBlockID && blueprintID && requestMock)) {
        return state;
      }

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      const idx = newMappingTestBlocks.findIndex((element) => {
        return element.id === mappingTestBlockID;
      });

      if (idx === -1) {
        return state;
      }
      const blockToUpdate = newMappingTestBlocks[idx];

      // Logic for updating ordered_blueprints_meta
      blockToUpdate["ordered_blueprints_meta"] = blockToUpdate["ordered_blueprints_meta"] || [];
      let foundBlueprintInOrdered = false;
      for (let meta of blockToUpdate["ordered_blueprints_meta"]) {
        if (meta.hasOwnProperty(blueprintID)) {
          foundBlueprintInOrdered = true;
          meta[blueprintID].request_mocks = [
            ...new Set([...meta[blueprintID].request_mocks, requestMock?.name ?? ""]),
          ];
          break;
        }
      }

      if (!foundBlueprintInOrdered) {
        const newBlueprintMeta = { [blueprintID]: { request_mocks: [requestMock?.name ?? ""] } };
        blockToUpdate["ordered_blueprints_meta"].push(newBlueprintMeta);
      }

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.UPDATE_MAPPING_TEST_FREEZE_TIME: {
      const { newDateTimeValue } = action;

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          frozen_time: newDateTimeValue,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.SET_OVERRIDE_LAST_RUN_AT_FOR_BLUEPRINT_IN_TEST_BLOCK: {
      const { mappingTestBlockID, blueprintID, overrideLastRunAtValue } = action;
      if (!(mappingTestBlockID && blueprintID)) {
        return state;
      }

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      const idx = newMappingTestBlocks.findIndex((element) => {
        return element.id === mappingTestBlockID;
      });

      if (idx === -1) {
        return state;
      }

      // New logic for ordered_blueprints_meta
      const orderedBlueprintsMeta = newMappingTestBlocks[idx]["ordered_blueprints_meta"] || [];
      let foundBlueprintInOrdered = false;
      orderedBlueprintsMeta.forEach((meta) => {
        if (meta.hasOwnProperty(blueprintID)) {
          foundBlueprintInOrdered = true;
          meta[blueprintID].override_last_run_at = overrideLastRunAtValue;
        }
      });

      if (!foundBlueprintInOrdered) {
        const newBlueprintMeta = {
          [blueprintID]: { request_mocks: [], override_last_run_at: overrideLastRunAtValue },
        };
        orderedBlueprintsMeta.push(newBlueprintMeta);
      }
      newMappingTestBlocks[idx]["ordered_blueprints_meta"] = orderedBlueprintsMeta;

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.SET_DISABLE_FILTER_BY_DATE_FOR_BLUEPRINT_IN_TEST_BLOCK: {
      const { mappingTestBlockID, blueprintID, disableFilterByDateValue } = action;
      if (!(mappingTestBlockID && blueprintID)) {
        return state;
      }

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      const idx = newMappingTestBlocks.findIndex((element) => {
        return element.id === mappingTestBlockID;
      });

      // Logic for ordered_blueprints_meta
      const orderedBlueprintsMeta = newMappingTestBlocks[idx]["ordered_blueprints_meta"] || [];

      orderedBlueprintsMeta.forEach((meta) => {
        if (meta.hasOwnProperty(blueprintID)) {
          meta[blueprintID].disable_filter_by_date = disableFilterByDateValue;
        }
      });

      if (!orderedBlueprintsMeta.some((meta) => meta.hasOwnProperty(blueprintID))) {
        const newBlueprintMeta = {
          [blueprintID]: { request_mocks: [], disable_filter_by_date: disableFilterByDateValue },
        };
        orderedBlueprintsMeta.push(newBlueprintMeta); // Push to the temporary variable
      }

      newMappingTestBlocks[idx]["ordered_blueprints_meta"] = orderedBlueprintsMeta;

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.REMOVE_MAPPING_TEST_BLOCK: {
      const { mappingTestBlockID } = action;
      if (!mappingTestBlockID) {
        return state;
      }

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks.filter(
            (block) => block.id != mappingTestBlockID
          ),
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.REMOVE_BLUEPRINT_FROM_MAPPING_TEST_BLOCK: {
      const { mappingTestBlockID, blueprintID } = action;
      if (!(mappingTestBlockID && blueprintID)) {
        return state;
      }

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      const idx = newMappingTestBlocks.findIndex((element) => {
        return element.id === mappingTestBlockID;
      });

      if (idx === -1) {
        return state;
      }

      // Logic for removing blueprint from ordered_blueprints_meta
      const orderedBlueprintsMeta = newMappingTestBlocks[idx]["ordered_blueprints_meta"] || [];
      const updatedOrderedBlueprintsMeta = orderedBlueprintsMeta.filter(
        (meta) => !meta.hasOwnProperty(blueprintID)
      );
      newMappingTestBlocks[idx]["ordered_blueprints_meta"] = updatedOrderedBlueprintsMeta;

      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }

    case MappingTestReducerActions.REMOVE_REQUEST_MOCK_FROM_MAPPING_TEST_BLOCK: {
      const { mappingTestBlockID, blueprintID, requestMock, deletedRequestMockName } = action;
      if (!mappingTestBlockID || !blueprintID) {
        return state;
      }

      if (!requestMock && !deletedRequestMockName) {
        return state;
      }

      const mockNameToRemove = deletedRequestMockName || requestMock?.name;

      const newMappingTestBlocks = [
        ...cloneDeep(present.mappingTestVersionUnderConstruction.mapping_test_blocks),
      ];

      const idx = newMappingTestBlocks.findIndex((element) => {
        return element.id === mappingTestBlockID;
      });

      if (idx === -1) {
        return state;
      }
      if (mockNameToRemove) {
        // Logic for ordered_blueprints_meta
        const orderedBlueprintsMeta = newMappingTestBlocks[idx]["ordered_blueprints_meta"] || [];
        orderedBlueprintsMeta.forEach((meta) => {
          if (meta[blueprintID]) {
            meta[blueprintID].request_mocks = (meta[blueprintID].request_mocks ?? []).filter(
              (mock) => mock !== mockNameToRemove
            );
          }
        });
        newMappingTestBlocks[idx]["ordered_blueprints_meta"] = orderedBlueprintsMeta;
      }
      const newPresent = {
        ...present,
        mappingTestVersionUnderConstruction: {
          ...present.mappingTestVersionUnderConstruction,
          mapping_test_blocks: newMappingTestBlocks,
        },
      };

      return updateForNewPresent(newPresent);
    }
  }
};

const useMappingTestReducer = (
  initialPresent: MappingTestReducerStateInstance,
  mappingTestID: string
): [
  MappingTestReducerStateInstance,
  boolean,
  boolean,
  {
    save: () => void;
    updateMappingTestField: (newFieldKey: string, newFieldValue: unknown) => void;
    updateCommonModelCountAssertion: (commonModelID: string, count: number) => void;
    updateCommonModelCountAssertionForBlock: (
      commonModelID: string,
      mappingTestID: string,
      count: number
    ) => void;
    updateCommonModelExpectedMappings: (
      commonModelID: string,
      commonModelExpectedMappings: undefined | MappingTestCommonModelExpectedMappings
    ) => void;
    updateCommonModelExpectedMappingsForBlock: (
      mappingTestBlockID: string,
      commonModelID: string,
      commonModelExpectedMappings: undefined | MappingTestCommonModelExpectedMappings
    ) => void;
    updateMappingTestRequestBody: (apiRequestID: string, apiRequestBody: string) => void;
    updateMappingTestBPMetadata: (blueprintID: string, newMetaData: any) => void;
    resetMappingTestRequestBody: (apiRequestID: string) => void;
    addRequestMock: (requestMock: MappingTestRequestMock) => void;
    deleteRequestMock: (requestMock: MappingTestRequestMock) => void;
    editRequestMock: (requestMock: MappingTestRequestMock) => void;
    pasteRequestMock: (requestMock: MappingTestRequestMock) => void;
    addMappingTestBlock: (mappingTestID: string) => void;
    pasteMappingTestBlock: (mappingTestID: string, mappingTestBlock: MappingTestBlock) => void;
    removeMappingTestBlock: (mappingTestID: string) => void;
    addBlueprintToMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
    removeBlueprintFromMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
    setMappingTestFreezeTime: (newDateTimeValue: string | undefined) => void;
    addRequestMockToMappingTestBlock: (
      mappingTestBlockID: string,
      blueprintID: string,
      requestMock: MappingTestRequestMock
    ) => void;
    removeRequestMockFromMappingTestBlock: (
      mappingTestBlockID: string,
      blueprintID: string,
      requestMock: MappingTestRequestMock,
      deletedRequestMockName?: string
    ) => void;
    setOverrideLastRunAtValue: (
      mappingTestBlockID: string,
      blueprintID: string,
      overrideLastRunAtValue?: string | null
    ) => void;
    setDisableFilterByDateValue: (
      mappingTestBlockID: string,
      blueprintID: string,
      disableFilterByDateValue?: boolean
    ) => void;
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    stage: () => void;
    unstage: () => void;
    updateRelationName: (
      oldName: string,
      newName: string,
      mappingTestBlockID: string,
      commonModelID: string
    ) => void;
  }
] => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    {
      past: [],
      future: [],
      present: initialPresent,
      lastSavedMappingTest: initialPresent,
      isSaving: false,
    },
    undefined
  );

  const canUndo = state.past.length !== 0;
  const canRedo = state.future.length !== 0;
  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({ type: MappingTestReducerActions.UNDO });
    }
  }, [canUndo]);
  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({ type: MappingTestReducerActions.REDO });
    }
  }, [canRedo]);

  const updateMappingTestField = useCallback(
    (newFieldKey, newFieldValue) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_MAPPING_TEST_FIELD,
        newFieldKey,
        newFieldValue,
      }),
    []
  );
  const updateCommonModelCountAssertion = useCallback(
    (commonModelID, count) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_COMMON_MODEL_COUNT_ASSERTION,
        commonModelID,
        count,
      }),
    []
  );

  const updateCommonModelCountAssertionForBlock = useCallback(
    (mappingTestBlockID, commonModelID, count) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_COMMON_MODEL_COUNT_ASSERTION_FOR_BLOCK,
        mappingTestBlockID,
        commonModelID,
        count,
      }),
    []
  );

  const updateMappingTestBPMetadata = useCallback(
    (blueprintID, newFieldValue) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_MAPPING_TEST_BP_METADATA,
        blueprintID,
        newFieldValue,
      }),
    []
  );

  const updateMappingTestRequestBody = useCallback(
    (apiRequestID, apiRequestBody) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_MAPPING_TEST_REQUEST_BODY,
        apiRequestID,
        apiRequestBody,
      }),
    []
  );

  const addRequestMock = useCallback(
    (requestMock) =>
      dispatch({
        type: MappingTestReducerActions.ADD_REQUEST_MOCK,
        requestMock,
      }),
    []
  );

  const editRequestMock = useCallback(
    (requestMock) =>
      dispatch({
        type: MappingTestReducerActions.EDIT_REQUEST_MOCK,
        requestMock,
      }),
    []
  );

  const pasteRequestMock = useCallback(
    (requestMock) =>
      dispatch({
        type: MappingTestReducerActions.PASTE_REQUEST_MOCK,
        requestMock,
      }),
    []
  );

  const deleteRequestMock = useCallback(
    (requestMock) =>
      dispatch({
        type: MappingTestReducerActions.DELETE_REQUEST_MOCK,
        requestMock,
      }),
    []
  );

  const addMappingTestBlock = useCallback(
    (mappingTestID) =>
      dispatch({
        type: MappingTestReducerActions.ADD_MAPPING_TEST_BLOCK,
        mappingTestID,
      }),
    []
  );

  const pasteMappingTestBlock = useCallback(
    (mappingTestID, mappingTestBlock) =>
      dispatch({
        type: MappingTestReducerActions.PASTE_MAPPING_TEST_BLOCK,
        mappingTestID,
        mappingTestBlock,
      }),
    []
  );

  const addBlueprintToMappingTestBlock = useCallback(
    (mappingTestBlockID, blueprintID) =>
      dispatch({
        type: MappingTestReducerActions.ADD_BLUEPRINT_TO_MAPPING_TEST_BLOCK,
        mappingTestBlockID,
        blueprintID,
      }),
    []
  );

  const addRequestMockToMappingTestBlock = useCallback(
    (mappingTestBlockID, blueprintID, requestMock) =>
      dispatch({
        type: MappingTestReducerActions.ADD_REQUEST_MOCK_TO_MAPPING_TEST_BLOCK,
        mappingTestBlockID,
        blueprintID,
        requestMock,
      }),
    []
  );

  const removeMappingTestBlock = useCallback(
    (mappingTestBlockID) =>
      dispatch({
        type: MappingTestReducerActions.REMOVE_MAPPING_TEST_BLOCK,
        mappingTestBlockID,
      }),
    []
  );

  const removeBlueprintFromMappingTestBlock = useCallback(
    (mappingTestBlockID, blueprintID) =>
      dispatch({
        type: MappingTestReducerActions.REMOVE_BLUEPRINT_FROM_MAPPING_TEST_BLOCK,
        mappingTestBlockID,
        blueprintID,
      }),
    []
  );

  const removeRequestMockFromMappingTestBlock = useCallback(
    (mappingTestBlockID, blueprintID, requestMock, deletedRequestMockName) =>
      dispatch({
        type: MappingTestReducerActions.REMOVE_REQUEST_MOCK_FROM_MAPPING_TEST_BLOCK,
        mappingTestBlockID,
        blueprintID,
        requestMock,
        deletedRequestMockName,
      }),
    []
  );

  const setOverrideLastRunAtValue = useCallback(
    (mappingTestBlockID, blueprintID, overrideLastRunAtValue) =>
      dispatch({
        type: MappingTestReducerActions.SET_OVERRIDE_LAST_RUN_AT_FOR_BLUEPRINT_IN_TEST_BLOCK,
        mappingTestBlockID,
        blueprintID,
        overrideLastRunAtValue,
      }),
    []
  );

  const setMappingTestFreezeTime = useCallback(
    (newDateTimeValue) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_MAPPING_TEST_FREEZE_TIME,
        newDateTimeValue,
      }),
    []
  );

  const setDisableFilterByDateValue = useCallback(
    (mappingTestBlockID, blueprintID, disableFilterByDateValue) =>
      dispatch({
        type: MappingTestReducerActions.SET_DISABLE_FILTER_BY_DATE_FOR_BLUEPRINT_IN_TEST_BLOCK,
        mappingTestBlockID,
        blueprintID,
        disableFilterByDateValue,
      }),
    []
  );

  const resetMappingTestRequestBody = useCallback(
    (apiRequestID) =>
      dispatch({
        type: MappingTestReducerActions.RESET_MAPPING_TEST_REQUEST_BODY,
        apiRequestID,
      }),
    []
  );

  const save = useCallback(() => {
    dispatch({ type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVING, dispatch });
    dispatch({ type: MappingTestReducerActions.SAVE_DRAFT, dispatch, mappingTestID });
  }, [mappingTestID, dispatch]);

  const stage = useCallback(() => {
    dispatch({ type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVING, dispatch });
    dispatch({ type: MappingTestReducerActions.STAGE, dispatch, mappingTestID });
  }, [mappingTestID, dispatch]);

  const unstage = useCallback(() => {
    dispatch({ type: MappingTestReducerActions.MARK_MAPPING_TEST_AS_SAVING, dispatch });
    dispatch({ type: MappingTestReducerActions.UNSTAGE, dispatch, mappingTestID });
  }, [mappingTestID, dispatch]);

  const updateCommonModelExpectedMappings = useCallback(
    (commonModelID, commonModelExpectedMappings) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_COMMON_MODEL_EXPECTED_MAPPINGS,
        commonModelID,
        commonModelExpectedMappings,
      }),
    []
  );

  const updateCommonModelExpectedMappingsForBlock = useCallback(
    (mappingTestBlockID, commonModelID, commonModelExpectedMappings) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_COMMON_MODEL_EXPECTED_MAPPINGS_FOR_BLOCK,
        mappingTestBlockID,
        commonModelID,
        commonModelExpectedMappings,
      }),
    []
  );

  const updateRelationName = useCallback(
    (commonModelID, mappingTestBlockID, oldName, newName) =>
      dispatch({
        type: MappingTestReducerActions.UPDATE_RELATION_NAME,
        commonModelID,
        mappingTestBlockID,
        oldName,
        newName,
      }),
    []
  );

  const doesMappingTestHaveUnsavedChanges = useMemo(
    () => !isEqual(state.present, state.lastSavedMappingTest),
    [state.present, state.lastSavedMappingTest]
  );

  return [
    state.present,
    doesMappingTestHaveUnsavedChanges,
    state.isSaving,
    {
      updateMappingTestField: updateMappingTestField,
      updateCommonModelExpectedMappings,
      updateCommonModelExpectedMappingsForBlock,
      updateCommonModelCountAssertion,
      updateCommonModelCountAssertionForBlock,
      updateMappingTestRequestBody: updateMappingTestRequestBody,
      resetMappingTestRequestBody: resetMappingTestRequestBody,
      updateMappingTestBPMetadata: updateMappingTestBPMetadata,
      addRequestMock,
      editRequestMock,
      pasteRequestMock,
      deleteRequestMock,
      addMappingTestBlock,
      pasteMappingTestBlock,
      removeMappingTestBlock,
      addBlueprintToMappingTestBlock,
      removeBlueprintFromMappingTestBlock,
      addRequestMockToMappingTestBlock,
      removeRequestMockFromMappingTestBlock,
      setOverrideLastRunAtValue,
      setDisableFilterByDateValue,
      setMappingTestFreezeTime,
      undo,
      redo,
      save,
      stage,
      unstage,
      canUndo,
      canRedo,
      updateRelationName,
    },
  ];
};

export default useMappingTestReducer;
