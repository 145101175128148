import { useState, useCallback } from "react";
import { MappingTestSuiteMeta } from "../../../../models/MappingTests";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { fetchWithAuth } from "../../../../api-client/api_client";

interface DeleteTestSuiteProps {
  integrationID: string;
  setTestSuites: React.Dispatch<React.SetStateAction<MappingTestSuiteMeta[] | undefined>>;
  onClose: () => void;
}

const useDeleteTestSuite = ({ integrationID, setTestSuites, onClose }: DeleteTestSuiteProps) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const deleteTestSuite = useCallback(
    (MappingTestSuiteID: string) => {
      if (!MappingTestSuiteID) {
        return;
      }

      setIsLoadingDelete(true);

      fetchWithAuth({
        path: `/stallions/integrations/test-suite/${MappingTestSuiteID}`,
        method: "DELETE",
        body: {},
        onResponse: () => {
          showSuccessToast("Linked Account Mapping Test Suite successfully deleted!");
          setIsLoadingDelete(false);
          onClose();

          setTestSuites((prevTestSuites) =>
            prevTestSuites?.filter((suite) => suite.id !== MappingTestSuiteID)
          );
        },
        onError: () => {
          showErrorToast("Unable to delete Mapping Test Suite");
          setIsLoadingDelete(false);
        },
      });
    },
    [integrationID, setTestSuites, onClose]
  );

  return { deleteTestSuite, isLoadingDelete };
};

export default useDeleteTestSuite;
