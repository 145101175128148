import React, { createContext, useContext } from "react";
import { Blueprint, BlueprintTestPayload } from "../../../../../models/Blueprints";

// Define the context type
export interface PayloadContextState {
  blueprint: Blueprint;
  blueprintTestPayloads: BlueprintTestPayload[];
  setBlueprintTestPayloads: React.Dispatch<React.SetStateAction<BlueprintTestPayload[]>>;
  allBlueprintTestPayloads: BlueprintTestPayload[];
  setAllBlueprintTestPayloads: React.Dispatch<React.SetStateAction<BlueprintTestPayload[]>>;
  deletePayload: (payload: BlueprintTestPayload) => void;
  isLoadingDeletion: boolean;
  setIsLoadingDeletion: (x: boolean) => void;
  isShowingConfirmDeletePayloadModal: boolean;
  setIsShowingConfirmDeletePayloadModal: (x: boolean) => void;
  selectedTestPayload?: BlueprintTestPayload | null;
  setSelectedTestPayload: (x: BlueprintTestPayload | undefined | null) => void;
  globalVarsAsString: string | undefined;
  setGlobalVarsAsString: (x: string) => void;
  isShowingSavePayloadModal: boolean;
  setIsShowingSavePayloadModal: (x: boolean) => void;
  isLoadingSave: boolean;
  setIsLoadingSave: (x: boolean) => void;
  isLoadingGeneratedPayload: boolean;
  setIsLoadingGeneratedPayload: (x: boolean) => void;
  newGeneratedPayloadId: string | null;
  setNewGeneratedPayloadId: (x: string | null) => void;
  generateWritePayload: (
    common_model_object_id: string | null,
    linked_account_id: string | null
  ) => void;
  loadSelectedTestPayload: (id: string) => void;
  fetchSavedPayloads: () => void;
  upsertPayload: (
    payloadName: string,
    selectedLinkedAccountID: string | undefined,
    selectedTestCommonModel: string | undefined,
    onHide?: () => void,
    isSavingAsNewPayload?: boolean
  ) => void;
  isSelectedTestPayloadGenerated: boolean;
  hasModifiedPayload: boolean;
  canSavePayloadText: boolean;
  hasInputParameters: boolean;
  isSavingAsNewPayload: boolean;
  setIsSavingAsNewPayload: (x: boolean) => void;
  areInputParametersValid: boolean;
}

// Create the context
export const PayloadContext = createContext<PayloadContextState>({
  blueprint: {} as Blueprint,
  blueprintTestPayloads: [],
  setBlueprintTestPayloads: () => {},
  allBlueprintTestPayloads: [],
  setAllBlueprintTestPayloads: () => {},
  deletePayload: () => {},
  isLoadingDeletion: false,
  setIsLoadingDeletion: () => {},
  isShowingConfirmDeletePayloadModal: false,
  setIsShowingConfirmDeletePayloadModal: () => {},
  globalVarsAsString: undefined,
  setGlobalVarsAsString: () => {},
  selectedTestPayload: undefined,
  setSelectedTestPayload: () => {},
  isShowingSavePayloadModal: false,
  setIsShowingSavePayloadModal: () => {},
  isLoadingSave: false,
  setIsLoadingSave: () => {},
  isLoadingGeneratedPayload: false,
  setIsLoadingGeneratedPayload: () => {},
  newGeneratedPayloadId: null,
  setNewGeneratedPayloadId: () => {},
  generateWritePayload: () => {},
  loadSelectedTestPayload: () => {},
  fetchSavedPayloads: () => {},
  upsertPayload: () => {},
  isSelectedTestPayloadGenerated: false,
  hasModifiedPayload: false,
  canSavePayloadText: false,
  hasInputParameters: false,
  isSavingAsNewPayload: false,
  setIsSavingAsNewPayload: () => {},
  areInputParametersValid: false,
});

// Hook to use the context
export const usePayloadContext = () => {
  const context = useContext(PayloadContext);
  if (!context) {
    throw new Error("usePayloadContext must be used within a PayloadContextProvider");
  }
  return context;
};
