import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import MappingTestBlockExecutionStatusBadge from "../shared/MappingTestBlockExecutionStatusBadge";
import { MappingTestExecution } from "../../../models/MappingTests";
import { X } from "lucide-react";

interface Props {
  blockName: string;
  selectedBlockExecution: MappingTestExecution | undefined;
  setIsShowingRightPanel: (value: React.SetStateAction<boolean>) => void;
}

const MappingTestV2RightPanelHeader = ({
  blockName,
  selectedBlockExecution,
  setIsShowingRightPanel,
}: Props) => {
  return (
    <div>
      <div className="flex flex-row px-4 py-5 justify-between items-center">
        <div className="flex flex-row space-x-2 items-center">
          <Text variant="h5" className="text-black">
            {blockName}
          </Text>
          {selectedBlockExecution && (
            <MappingTestBlockExecutionStatusBadge testBlockExecution={selectedBlockExecution} />
          )}
        </div>
        <Button
          size="sm"
          onClick={() => setIsShowingRightPanel(false)}
          variant={ButtonVariant.IconOnly}
        >
          <X size={16} className="text-gray-60" />
        </Button>
      </div>
      <hr className="text-gray-50 h-[0.5px] my-0" />
    </div>
  );
};

export default MappingTestV2RightPanelHeader;
