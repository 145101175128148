import { AuthType } from "../../../../../models/Entities";
import { GrantTypes } from "../../../../integrations/auth-config/AuthConfigShared";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import { Row } from "../../../shared/postman-table/PostmanTableRow";
import GrantTypeButtons from "../buttons/GrantTypeButtons";
import OAuthTypeButtons from "../buttons/OAuthTypeButtons";
import BasicAuthNoTokenExchange from "../non-oauth/BasicAuthNoTokenExchange";
import AdditionalAuthFieldKeysFields from "../shared/AdditionalAuthFieldKeysFields";
import AdditionalHeaderFields from "../shared/AdditionalHeaderFields";
import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";
import LineIndent from "../shared/LineIndent";

interface AuthenticationConfigurationSetupOptionsRenderedComponentsProps {
  grantType: GrantTypes | undefined;
  setGrantType: React.Dispatch<React.SetStateAction<GrantTypes | undefined>>;
  actualAuthType: AuthType | undefined;
  setActualAuthType: React.Dispatch<React.SetStateAction<AuthType | undefined>>;
  isOAuth: boolean | undefined;
  oAuthParamsScopes: string | undefined;
  setOAuthParamsScopes: React.Dispatch<React.SetStateAction<string | undefined>>;
  isNonOAuthBasedTokenExchange: boolean | undefined;
  setIsNonOAuthBasedTokenExchange: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  basicAuthKeyHeaderFormat: Row[];
  setBasicAuthKeyHeaderFormat: React.Dispatch<React.SetStateAction<Row[]>>;
  additionalHeaderFields: Row[];
  setAdditionalHeaderFields: React.Dispatch<React.SetStateAction<Row[]>>;
  basicAuthAdditionalAuthFieldKeys: string[] | null;
  setBasicAuthAdditionalAuthFieldKeys: React.Dispatch<React.SetStateAction<string[] | null>>;
  isSoapIntegration: boolean;
  oAuthSetSoapBodyHeaderToNull: boolean;
  setOAuthSetSoapBodyHeaderToNull: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthenticationConfigurationSetupOptionsRenderedComponents = ({
  grantType,
  actualAuthType,
  setActualAuthType,
  isOAuth,
  setGrantType,
  oAuthParamsScopes,
  setOAuthParamsScopes,
  isNonOAuthBasedTokenExchange,
  setIsNonOAuthBasedTokenExchange,
  basicAuthKeyHeaderFormat,
  setBasicAuthKeyHeaderFormat,
  additionalHeaderFields,
  setAdditionalHeaderFields,
  basicAuthAdditionalAuthFieldKeys,
  setBasicAuthAdditionalAuthFieldKeys,
  isSoapIntegration,
  oAuthSetSoapBodyHeaderToNull,
  setOAuthSetSoapBodyHeaderToNull,
}: AuthenticationConfigurationSetupOptionsRenderedComponentsProps) => {
  if (isOAuth) {
    return (
      <LineIndent className="mt-6 pl-7">
        <OAuthTypeButtons actualAuthType={actualAuthType} setActualAuthType={setActualAuthType} />
        <GrantTypeButtons className="mt-6" grantType={grantType} setGrantType={setGrantType} />
        <AdditionalHeaderFields
          className="mt-6"
          additionalHeaderFields={additionalHeaderFields}
          setAdditionalHeaderFields={setAdditionalHeaderFields}
        />
        <TextFieldHeader
          dataTestID="field-auth-config-scopes"
          className="mt-6"
          title="Scopes"
          placeholder="Enter scopes value..."
          subtitle="If scopes is required in the parameters, enter the value below."
          learnMoreText="For example, the Sharepoint integration has a scopes parameter value of 'offline_access https://graph.microsoft.com/.default'"
          value={oAuthParamsScopes}
          onChange={(e) => setOAuthParamsScopes(e.target.value)}
          multiline
          rows={1}
        />
        <AdditionalAuthFieldKeysFields
          className="mt-6"
          basicAuthAdditionalAuthFieldKeys={basicAuthAdditionalAuthFieldKeys}
          setBasicAuthAdditionalAuthFieldKeys={setBasicAuthAdditionalAuthFieldKeys}
        />
        {isSoapIntegration && (
          <BinaryChoiceButtons
            dataTestID="field-auth-config-should-set-soap-body-header-null"
            className="mt-6"
            title="Should we set the SOAP body header to null for API requests?"
            subtitle="Enable this option to remove SOAP request header values during API requests."
            learnMoreText="For example, in the Workday integration, which uses a hybrid authentication method combining OAuth and basic auth, setting this option to true is necessary to clear the header section within the request body."
            binaryChoice={oAuthSetSoapBodyHeaderToNull}
            setBinaryChoice={setOAuthSetSoapBodyHeaderToNull}
          />
        )}
      </LineIndent>
    );
  } else if (isOAuth === false) {
    return (
      <>
        <LineIndent className="mt-6 pl-7">
          <BinaryChoiceButtons
            dataTestID="field-auth-config-is-non-oauth-based-token-exchange"
            title="Is there a non-OAuth based token exchange?"
            binaryChoice={isNonOAuthBasedTokenExchange}
            setBinaryChoice={setIsNonOAuthBasedTokenExchange}
            required
          />
          {isNonOAuthBasedTokenExchange ? (
            <>
              <BasicAuthNoTokenExchange
                className="mt-6"
                basicAuthKeyHeaderFormat={basicAuthKeyHeaderFormat}
                setBasicAuthKeyHeaderFormat={setBasicAuthKeyHeaderFormat}
                subtitle={
                  "If your integration requires a specific authentication key header format for every request, enter it below. In most cases, this value remains unchanged; HRWorks is an example of an integration that utilizes these values."
                }
                showHelp={false}
              />
              <AdditionalHeaderFields
                className="mt-6"
                additionalHeaderFields={additionalHeaderFields}
                setAdditionalHeaderFields={setAdditionalHeaderFields}
              />
            </>
          ) : (
            <BasicAuthNoTokenExchange
              className="mt-6"
              basicAuthKeyHeaderFormat={basicAuthKeyHeaderFormat}
              setBasicAuthKeyHeaderFormat={setBasicAuthKeyHeaderFormat}
            />
          )}
          <AdditionalAuthFieldKeysFields
            className="mt-6"
            basicAuthAdditionalAuthFieldKeys={basicAuthAdditionalAuthFieldKeys}
            setBasicAuthAdditionalAuthFieldKeys={setBasicAuthAdditionalAuthFieldKeys}
          />
        </LineIndent>
      </>
    );
  }
  // returning null for the case where isOAuth is undefined
  return null;
};

export default AuthenticationConfigurationSetupOptionsRenderedComponents;
