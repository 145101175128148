import { ButtonVariant, Dialog, Text, TextField } from "@merge-api/merge-javascript-shared";
import { useEffect, useState } from "react";
import { LinkedAccount } from "../../../../../models/Entities";
import { filterLinkedAccountTypeaheadOptions } from "../../../../shared/linked-account-typeahead/LinkedAccountTypeaheadFilter";
import LinkedAccountTypeaheadRenderOption from "../../../../shared/linked-account-typeahead/LinkedAccountTypeaheadRenderOption";
import LeftPanelTypeahead from "../../shared/LeftPanelTypeahead";
import { usePayloadContext } from "../context/PayloadContext";

interface Props {
  testLinkedAccounts: LinkedAccount[];
  isSavingAsNewPayload: boolean;
  currentTestLinkedAccount: undefined | string;
  selectedTestCommonModel: undefined | string;
}

function BlueprintEditorLeftPanelSavePayloadModal({
  testLinkedAccounts,
  currentTestLinkedAccount,
  selectedTestCommonModel,
  isSavingAsNewPayload,
}: Props) {
  const {
    isLoadingSave,
    setIsShowingConfirmDeletePayloadModal,
    upsertPayload,
    allBlueprintTestPayloads,
    selectedTestPayload,
    isSelectedTestPayloadGenerated,
    isShowingSavePayloadModal: show,
    setIsShowingSavePayloadModal: setShow,
  } = usePayloadContext();
  const [payloadName, setPayloadName] = useState(
    `${selectedTestPayload?.name ?? ""}${isSavingAsNewPayload ? " (copy)" : ""}`
  );
  const [selectedLinkedAccountID, setSelectedLinkedAccountID] = useState<string | undefined>(
    selectedTestPayload?.linked_account_id ?? currentTestLinkedAccount
  );

  useEffect(() => {
    setPayloadName(`${selectedTestPayload?.name ?? ""}${isSavingAsNewPayload ? " (copy)" : ""}`);
    setSelectedLinkedAccountID(selectedTestPayload?.linked_account_id ?? currentTestLinkedAccount);
  }, [selectedTestPayload, isSavingAsNewPayload, currentTestLinkedAccount]);

  const isValidPayloadName = (): boolean => {
    return !!payloadName && payloadName.length <= 100;
  };

  const isPayloadNameDuplicate = (): boolean => {
    const foundPayload =
      allBlueprintTestPayloads.find((payload) => payload.name === payloadName) !== undefined;
    return isSavingAsNewPayload
      ? foundPayload
      : foundPayload && payloadName !== selectedTestPayload?.name;
  };

  const isSettingsPage =
    !isSavingAsNewPayload && !!selectedTestPayload && !isSelectedTestPayloadGenerated;

  const onHide = () => {
    setShow(false);
  };

  const deletePayload = () => {
    onHide();
    setIsShowingConfirmDeletePayloadModal(true);
  };

  // If we're saving as new, title is "Save as new payload". If we're updating an existing payload, title is "Payload settings".
  const dialogTitle = isSavingAsNewPayload
    ? "Save as new payload"
    : isSettingsPage
    ? "Payload settings"
    : "Save payload";

  // Conditions we disable the primary button:
  // - No payload name entered
  // - Payload name is invalid
  // - Payload name is duplicate
  const isPrimaryButtonDisabled = !payloadName || !isValidPayloadName() || isPayloadNameDuplicate();

  const renderLinkedAccountTitle = () => (
    <div className="flex flex-col">
      <Text variant="h6">Associate to Linked Account</Text>
      <Text variant="sm" className="text-gray-60">
        Associate the payload with a given Linked Account if the values are specific to that Linked
        Account. Leave blank if the payload can be used across all Linked Accounts.
      </Text>
    </div>
  );

  return (
    <Dialog
      title={dialogTitle}
      className="bg-white"
      open={show}
      onClose={onHide}
      variant="md"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      onPrimaryButtonClick={() =>
        upsertPayload(
          payloadName,
          selectedLinkedAccountID,
          selectedTestCommonModel,
          onHide,
          isSavingAsNewPayload
        )
      }
      primaryButtonLoading={isLoadingSave}
      primaryButtonDisabled={isPrimaryButtonDisabled}
      secondaryButtonText={isSettingsPage ? "Delete payload" : undefined}
      secondaryButtonVariant={isSettingsPage ? ButtonVariant.DangerText : undefined}
      onSecondaryButtonClick={isSettingsPage ? deletePayload : undefined}
    >
      <div className="flex flex-col space-y-4">
        <TextField
          label="Payload name"
          value={payloadName}
          onChange={(e) => setPayloadName(e.target.value)}
          error={(!!payloadName && !isValidPayloadName()) || isPayloadNameDuplicate()}
          errorText={
            !isValidPayloadName()
              ? "Enter a name between 1 and 100 characters long"
              : isPayloadNameDuplicate()
              ? "Payload name already exists"
              : undefined
          }
        />
        <div className="flex flex-col space-y-2">
          <LeftPanelTypeahead
            title={renderLinkedAccountTitle()}
            placeholder="Select Linked Account"
            onChange={(_, option: LinkedAccount | undefined) => {
              setSelectedLinkedAccountID(option?.id ?? undefined);
            }}
            value={testLinkedAccounts.find(
              (linkedAccount) => linkedAccount?.id === selectedLinkedAccountID
            )}
            getOptionLabel={(option: LinkedAccount) => {
              return option.end_user?.organization_name;
            }}
            renderOption={(option: LinkedAccount) => (
              <LinkedAccountTypeaheadRenderOption option={option} />
            )}
            filterOptions={(options: LinkedAccount[], state: any) => {
              return filterLinkedAccountTypeaheadOptions(options, state);
            }}
            options={testLinkedAccounts}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default BlueprintEditorLeftPanelSavePayloadModal;
