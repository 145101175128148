import { BlueprintStep, StepParameterTracingTarget } from "../../../../models/Blueprints";
import { getMappedStepsForParameterValue } from "./StepInitialization";
import { generateFlattenedMapOfStepsAndVariables, initializeStack } from "./TracingInitialization";
import {
  addToStepIDsStackFromStepIDs,
  addToStepIDsStackFromVariables,
  mappedStepInputParameterData,
  upsertMappedStepInputParameterData,
} from "./SharedUtils";
import { cleanUpTracedStepIDs } from "./TracingPostProcessing";

/**
 * Computes traced steps for Blueprint, given a selected step's parameter key
 * It does this by first creating a dictionary of {step_id: step}
 * Then DFS up the dictionary, starting from the selected step's parameter key
 */
export const computeTracedStepIDsForBlueprint = (
  steps: BlueprintStep[],
  stepParameterToTrace: StepParameterTracingTarget
): string[] => {
  let tracedStepIDs: string[] = [stepParameterToTrace.step_id];

  // Initialize flattened map of steps
  const precomputedData = generateFlattenedMapOfStepsAndVariables(steps);
  const flatMapOfSteps = precomputedData.flat_map_of_steps;
  const mapOfVariablesToOrderedStepIDs = precomputedData.map_of_variables_to_ordered_step_ids;

  // DFS starting at the target
  let stackStepIDs = initializeStack(
    stepParameterToTrace,
    flatMapOfSteps,
    mapOfVariablesToOrderedStepIDs
  );
  let visitedStepIDs: Set<string> = new Set(); // To prevent unnecessary duplicate visits

  // Run DFS to collect step_ids and variables
  while (stackStepIDs.length > 0) {
    const currentStepID = stackStepIDs.pop();
    if (currentStepID && !visitedStepIDs.has(currentStepID)) {
      visitedStepIDs.add(currentStepID);
      tracedStepIDs.push(currentStepID);
      const step = currentStepID in flatMapOfSteps ? flatMapOfSteps[currentStepID] : null;
      if (step) {
        // Find all other steps that map to this step's inputs!
        let stepInputData: mappedStepInputParameterData = {
          step_ids: new Set(),
          variables: new Set(),
        };
        Object.entries(step.parameter_values).forEach(([parameterKey, parameterValue]) => {
          stepInputData = upsertMappedStepInputParameterData(
            stepInputData,
            getMappedStepsForParameterValue(parameterValue, parameterKey, step)
          );
        });
        // Add newly found step ids and variables to the stacks
        stackStepIDs = addToStepIDsStackFromStepIDs(stackStepIDs, stepInputData, visitedStepIDs);
        stackStepIDs = addToStepIDsStackFromVariables(
          stackStepIDs,
          stepInputData,
          mapOfVariablesToOrderedStepIDs,
          currentStepID,
          visitedStepIDs
        );
      }
    }
  }

  // Re-order based on Blueprint step ordering, and remove duplicates
  const tracedStepIDSet: Set<string> = new Set(tracedStepIDs);
  tracedStepIDs = cleanUpTracedStepIDs(steps, tracedStepIDSet);

  return tracedStepIDs;
};
