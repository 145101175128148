import {
  Badge,
  Button,
  ButtonVariant,
  Card,
  Dialog,
  Link,
  Text,
} from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import {
  LinkedAccountAccessRequest,
  LinkedAccountAccessRequestStatus,
} from "../../../../models/Entities";
import { formatShorthandDate } from "../../../integration-builder/utils/helpers";
import { useCustomerLinkedAccountContext } from "../../context/CustomerLinkedAccountAccessContext";

interface CustomerLinkedAccountAccessRequestProps {
  accessRequest: LinkedAccountAccessRequest;
}

const CustomerLinkedAccountAccessRequest = ({
  accessRequest,
}: CustomerLinkedAccountAccessRequestProps) => {
  const {
    withdrawLinkedAccountAccessRequest,
    acknowledgeLinkedAccountAccessRequest,
  } = useCustomerLinkedAccountContext();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const handleButtonClick = () => {
    if (accessRequest.status === LinkedAccountAccessRequestStatus.APPROVED) {
      setShowConfirmModal(true);
    } else if (accessRequest.status === LinkedAccountAccessRequestStatus.PENDING) {
      withdrawLinkedAccountAccessRequest();
    } else {
      acknowledgeLinkedAccountAccessRequest();
    }
  };

  const useCustomerLinkedAccount = () => {
    acknowledgeLinkedAccountAccessRequest(true);
    setShowConfirmModal(false);
  };

  const renderBadge = () => {
    switch (accessRequest.status) {
      case LinkedAccountAccessRequestStatus.APPROVED:
        return <Badge color="teal">Approved</Badge>;
      case LinkedAccountAccessRequestStatus.REJECTED:
        return <Badge color="red">Denied</Badge>;
      case LinkedAccountAccessRequestStatus.PENDING:
        return <Badge color="yellow">Pending</Badge>;
      default:
        return null;
    }
  };

  const getButtonText = () => {
    switch (accessRequest.status) {
      case LinkedAccountAccessRequestStatus.APPROVED:
        return "Use Linked Account";
      default:
        return "Clear request";
    }
  };

  return (
    <Card variant="outline" className="p-3 space-y-2">
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between">
          <Text>Customer Linked Account request</Text>
          {renderBadge()}
        </div>
        <Text variant="sm" className="text-gray-70">
          {`Requested on ${formatShorthandDate(accessRequest.created_at)}`}
        </Text>
        <Text
          variant="sm"
          className="text-gray-70"
        >{`${accessRequest.organization_name} - ${accessRequest.end_user_name}`}</Text>
        <Text variant="sm" className="text-gray-70">
          {`ID: ${accessRequest.linked_account_id}`}
        </Text>
        <Text variant="sm" className="text-gray-70">
          {accessRequest.description}
        </Text>
        <Text variant="sm" className="text-gray-70">
          <Link href={accessRequest.ticket_url} target="_blank" className="truncate block w-full">
            {accessRequest.ticket_url}
          </Link>
        </Text>
      </div>
      <Button
        className="w-full"
        variant={
          accessRequest.status === LinkedAccountAccessRequestStatus.APPROVED
            ? ButtonVariant.SecondaryBlue
            : ButtonVariant.DangerText
        }
        size="sm"
        onClick={handleButtonClick}
      >
        {getButtonText()}
      </Button>
      <Dialog
        open={showConfirmModal}
        title="Confirm usage"
        onClose={() => setShowConfirmModal(false)}
        primaryButtonVariant={ButtonVariant.PrimaryBlue}
        primaryButtonText="I understand"
        onPrimaryButtonClick={useCustomerLinkedAccount}
      >
        <Text>
          Running a customer Linked Account exposes sensitive data. Please use with caution NOTE:
          Running Blueprints using this Linked Account will NOT publish any common models in the
          customer's account.
        </Text>
      </Dialog>
    </Card>
  );
};

export default CustomerLinkedAccountAccessRequest;
