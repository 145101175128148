import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";
import { usePayloadContext } from "../context/PayloadContext";

const PayloadHeader = () => {
  const { blueprint } = usePayloadContext();

  const inputParametersSubtitle = blueprint?.report_template_id
    ? "Upload new test Report files or select which Report file to use in the test run."
    : "For writes, pass in valid JSON representing nested common model. If webhook, nest under 'webhook_payload' key.";

  return (
    <div className="flex flex-row items-center">
      <Text variant="h6">Payload</Text>
      <Tooltip title={inputParametersSubtitle}>
        <Info size={16} className="ml-2" />
      </Tooltip>
    </div>
  );
};

export default PayloadHeader;
