import { BlueprintStep } from "../../../../models/Blueprints";

export interface stepsDataForTracing {
  flat_map_of_steps: Record<string, BlueprintStep>;
  map_of_variables_to_ordered_step_ids: Record<string, string[]>;
}

export interface mappedStepInputParameterData {
  step_ids: Set<string>;
  variables: Set<string>;
}

/**
 * Helper function to process insert child mappedStepInputParameterData into parent mappedStepInputParameterData
 */
export const upsertMappedStepInputParameterData = (
  data: mappedStepInputParameterData,
  childData: mappedStepInputParameterData
): mappedStepInputParameterData => {
  childData.step_ids.forEach((stepID) => data.step_ids.add(stepID));
  childData.variables.forEach((variable) => data.variables.add(variable));
  return data;
};

/**
 * Helper function to adding to stack from step ids, for computeTracedStepIDsForBlueprint
 */
export const addToStepIDsStackFromStepIDs = (
  stackStepIDs: string[],
  stepInputData: mappedStepInputParameterData,
  visitedStepIDs?: Set<string>
): string[] => {
  stepInputData.step_ids.forEach((stepID) => {
    if (!visitedStepIDs || !visitedStepIDs.has(stepID)) {
      stackStepIDs.push(stepID);
    }
  });
  return stackStepIDs;
};

/**
 * Helper function for adding to stack from variables, for computeTracedStepIDsForBlueprint
 */
export const addToStepIDsStackFromVariables = (
  stackStepIDs: string[],
  stepInputData: mappedStepInputParameterData,
  mapOfVariablesToOrderedStepIDs: Record<string, string[]>,
  currentStepID: string,
  visitedStepIDs?: Set<string>
): string[] => {
  stepInputData.variables.forEach((variable) => {
    if (variable in mapOfVariablesToOrderedStepIDs) {
      for (const stepID of mapOfVariablesToOrderedStepIDs[variable]) {
        if (!visitedStepIDs || !visitedStepIDs.has(stepID)) {
          stackStepIDs.push(stepID);
        }
        // Break if we reach currentStepID, since we don't care about steps after it that touch variable
        if (stepID == currentStepID) {
          break;
        }
      }
    }
  });
  return stackStepIDs;
};
