import { Col, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import FormField, { FormFieldCommonProps } from "./FormField";

interface Props extends FormFieldCommonProps {
  choices: Array<{
    id: string;
    label: string;
    value: { stepType: string; stepImg: string; helptext: string };
  }>;
  currentValue: any;
  placeholder: string;
  onChange: (e: any) => void;
  title: string;
}

const SearchableDropdown = ({ choices, onChange, placeholder, title }: Props) => (
  <FormField title={title}>
    <Typeahead
      id="Searchable Dropdown"
      options={choices}
      onChange={onChange}
      placeholder={placeholder}
      renderMenuItemChildren={(option: any) => (
        <Row key={option.id} style={{ display: "flex", flexDirection: "row" }}>
          <Col xs={3}>
            <div style={{ marginRight: "10px" }}>
              {option.value.stepImg ? (
                <img src={option.value.stepImg} className="card-image" alt="" />
              ) : (
                <div className="card-image d-flex align-items-center justify-content-center trigger-card-image">
                  <span className={"fe text-white"} style={{ fontSize: "24px" }} />
                </div>
              )}
            </div>
          </Col>
          <Col style={{ justifyContent: "left" }} xs={9}>
            <Row>
              <span>{option.label}</span>
            </Row>
            <Row>
              <span className="small text-muted">{option.value.helptext}</span>
            </Row>
          </Col>
        </Row>
      )}
    />
  </FormField>
);

export default SearchableDropdown;
