import { useState } from "react";
import {
  Blueprint,
  BlueprintOperationType,
  BlueprintTestPayload,
  JSONObjectSchema,
  ReportFile,
} from "../../../../../models/Blueprints";
import { LinkedAccount } from "../../../../../models/Entities";
import DeletionConfirmationModal from "../../../../shared/DeletionConfirmationModal";
import Dropdown from "../../../right-panel/Dropdown";
import BlueprintEditorLeftPanelSavePayloadModal from "./BlueprintEditorLeftPanelSavePayloadModal";
import { usePayloadContext } from "../context/PayloadContext";
import PayloadContextProvider from "../context/PayloadContextProvider";
import DiscardChangesModal from "./DiscardChangesModal";
import PayloadHeader from "./PayloadHeader";
import PayloadInputs from "./PayloadInputs";

interface Props {
  blueprint: Blueprint;
  hasInputParameters: boolean;
  blueprintTestPayloads: BlueprintTestPayload[];
  setBlueprintTestPayloads: React.Dispatch<React.SetStateAction<BlueprintTestPayload[]>>;
  selectedTestPayload: BlueprintTestPayload | undefined | null;
  setSelectedTestPayload: React.Dispatch<
    React.SetStateAction<BlueprintTestPayload | null | undefined>
  >;
  globalVarsAsString: string | undefined;
  setGlobalVarsAsString: (x: string) => void;
  testLinkedAccounts: LinkedAccount[];
  selectedTestLinkedAccount: undefined | string;
  selectedTestCommonModel: undefined | string;
  updatedParameterSchema: JSONObjectSchema | undefined;
  updateToLatestInputParameterSchema: () => void;
  shouldDisableGeneratePayload: boolean;
  setSelectedTestCommonModel: (x: undefined | string) => void;
  areInputParametersValid: boolean;
  reportFileID: string | undefined;
  setReportFileID: (x: string | undefined) => void;
  reportFiles: Array<ReportFile>;
  initializedSchemaForDeletePayload: string;
}

/**
 * Renders payload fields for running Blueprints with input parameters
 * ie.: Running a CREATE Blueprint with payload
 */
const BlueprintLeftPanelConsolePayload = ({
  selectedTestCommonModel,
  testLinkedAccounts,
  selectedTestLinkedAccount,
  updatedParameterSchema,
  updateToLatestInputParameterSchema,
  shouldDisableGeneratePayload,
  reportFileID,
  setReportFileID,
  reportFiles,
}: Props) => {
  const {
    blueprint,
    isShowingConfirmDeletePayloadModal,
    setIsShowingConfirmDeletePayloadModal,
    isLoadingDeletion,
    setIsLoadingDeletion,
    deletePayload,
    selectedTestPayload,
    setSelectedTestPayload,
    loadSelectedTestPayload,
    hasInputParameters,
    isSavingAsNewPayload,
  } = usePayloadContext();
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const [payloadToChange, setPayloadToChange] = useState<BlueprintTestPayload | null>(null);

  // If a Blueprint doesn't have input parameters, or is not a valid type, we do not render payload fields
  if (!hasInputParameters || blueprint.operation_type === BlueprintOperationType.PROXY) {
    return null;
  }

  // Handler for changing the selected payload in the Select component
  const changeSelectedPayload = (payload: BlueprintTestPayload | null) => {
    if (payload) {
      loadSelectedTestPayload(payload.id);
    } else {
      setSelectedTestPayload(null);
    }
  };

  const handleDeleteConfirmation = () => {
    setIsLoadingDeletion(true);
    if (selectedTestPayload) {
      deletePayload(selectedTestPayload);
    }
  };

  const handleDiscardChanges = () => {
    changeSelectedPayload(payloadToChange);
    setIsDiscardChangesModalOpen(false);
  };

  return (
    <>
      <BlueprintEditorLeftPanelSavePayloadModal
        testLinkedAccounts={testLinkedAccounts}
        isSavingAsNewPayload={isSavingAsNewPayload}
        currentTestLinkedAccount={selectedTestLinkedAccount}
        selectedTestCommonModel={selectedTestCommonModel}
      />
      <DeletionConfirmationModal
        selectedObjectType="Payload"
        associatedObjectsName={null}
        show={isShowingConfirmDeletePayloadModal}
        onHide={() => setIsShowingConfirmDeletePayloadModal(false)}
        isLoading={isLoadingDeletion}
        onConfirm={handleDeleteConfirmation}
      />
      <DiscardChangesModal
        open={isDiscardChangesModalOpen}
        onClose={() => setIsDiscardChangesModalOpen(false)}
        onPrimaryButtonClick={handleDiscardChanges}
      />
      <div className="flex flex-col space-y-2">
        <PayloadHeader />
        {!blueprint.report_template_id ? (
          <PayloadInputs
            selectedTestCommonModel={selectedTestCommonModel}
            selectedTestLinkedAccount={selectedTestLinkedAccount}
            shouldDisableGeneratePayload={shouldDisableGeneratePayload}
            setIsDiscardChangesModalOpen={setIsDiscardChangesModalOpen}
            setPayloadToChange={setPayloadToChange}
            updatedParameterSchema={updatedParameterSchema}
            updateToLatestInputParameterSchema={updateToLatestInputParameterSchema}
            changeSelectedPayload={changeSelectedPayload}
          />
        ) : (
          <Dropdown
            placeholder="Select Report"
            onChange={(e: any) => setReportFileID(e.target.value)}
            currentValue={reportFileID}
            choices={(reportFiles ?? [])
              .filter((file) => file.linked_account_id == selectedTestLinkedAccount)
              .map((file) => {
                return { id: file.id, name: file.name ?? file.filename };
              })}
          />
        )}
      </div>
    </>
  );
};

const BlueprintLeftPanelConsolePayloadWithContext = ({
  blueprint,
  hasInputParameters,
  blueprintTestPayloads,
  setBlueprintTestPayloads,
  selectedTestPayload,
  setSelectedTestPayload,
  globalVarsAsString,
  setGlobalVarsAsString,
  selectedTestCommonModel,
  testLinkedAccounts,
  selectedTestLinkedAccount,
  setSelectedTestCommonModel,
  updatedParameterSchema,
  updateToLatestInputParameterSchema,
  shouldDisableGeneratePayload,
  areInputParametersValid,
  reportFileID,
  setReportFileID,
  reportFiles,
  initializedSchemaForDeletePayload,
}: Props) => {
  return (
    <PayloadContextProvider
      blueprint={blueprint}
      blueprintTestPayloads={blueprintTestPayloads}
      setBlueprintTestPayloads={setBlueprintTestPayloads}
      selectedTestPayload={selectedTestPayload}
      setSelectedTestPayload={setSelectedTestPayload}
      globalVarsAsString={globalVarsAsString}
      setGlobalVarsAsString={setGlobalVarsAsString}
      setSelectedTestCommonModel={setSelectedTestCommonModel}
      initializedSchemaForDeletePayload={initializedSchemaForDeletePayload}
      selectedTestLinkedAccountID={selectedTestLinkedAccount}
      areInputParametersValid={areInputParametersValid}
    >
      <BlueprintLeftPanelConsolePayload
        blueprint={blueprint}
        hasInputParameters={hasInputParameters}
        blueprintTestPayloads={blueprintTestPayloads}
        setBlueprintTestPayloads={setBlueprintTestPayloads}
        selectedTestPayload={selectedTestPayload}
        setSelectedTestPayload={setSelectedTestPayload}
        globalVarsAsString={globalVarsAsString}
        setGlobalVarsAsString={setGlobalVarsAsString}
        selectedTestCommonModel={selectedTestCommonModel}
        setSelectedTestCommonModel={setSelectedTestCommonModel}
        testLinkedAccounts={testLinkedAccounts}
        selectedTestLinkedAccount={selectedTestLinkedAccount}
        updatedParameterSchema={updatedParameterSchema}
        updateToLatestInputParameterSchema={updateToLatestInputParameterSchema}
        shouldDisableGeneratePayload={shouldDisableGeneratePayload}
        areInputParametersValid={areInputParametersValid}
        reportFileID={reportFileID}
        setReportFileID={setReportFileID}
        reportFiles={reportFiles}
        initializedSchemaForDeletePayload={initializedSchemaForDeletePayload}
      />
    </PayloadContextProvider>
  );
};

export default BlueprintLeftPanelConsolePayloadWithContext;
