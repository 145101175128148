import {
  BlueprintParameterValue,
  BlueprintStep,
  StepParameterTracingTarget,
} from "../../../../models/Blueprints";
import {
  addToStepIDsStackFromStepIDs,
  addToStepIDsStackFromVariables,
  stepsDataForTracing,
} from "./SharedUtils";
import { getMappedStepsForParameterValue } from "./StepInitialization";
import { getMappedVariablesForStep } from "./VariableInitialization";

/**
 * Generates flattened map of steps from Blueprint's steps {step_id: step}
 * Helper function for other computation functions like traced steps
 * variable map is ordered to ensure only previous steps are processed in addToStepIDsStack
 */
export const generateFlattenedMapOfStepsAndVariables = (
  steps: BlueprintStep[]
): stepsDataForTracing => {
  let map: Record<string, BlueprintStep> = {};
  let variableOrderedMap: Record<string, string[]> = {};
  steps.forEach((step) => {
    // Store step in map
    map[step.id] = step;
    // Store & compute if step alters any variables
    const variables = getMappedVariablesForStep(step);
    if (variables) {
      variables.forEach((variable) => {
        if (!(variable in variableOrderedMap)) {
          variableOrderedMap[variable] = [step.id];
        } else {
          variableOrderedMap[variable].push(step.id);
        }
      });
    }
    if (step.paths) {
      Object.values(step.paths).forEach((pathSteps) => {
        const childData = generateFlattenedMapOfStepsAndVariables(pathSteps);
        // Add children step map
        map = { ...map, ...childData.flat_map_of_steps };
        // Add children variable maps
        Object.entries(childData.map_of_variables_to_ordered_step_ids).forEach(
          ([variable, step_ids]) => {
            if (!(variable in variableOrderedMap)) {
              variableOrderedMap[variable] = step_ids;
            } else {
              step_ids.forEach((stepID) => variableOrderedMap[variable].push(stepID));
            }
          }
        );
      });
    }
  });
  return {
    flat_map_of_steps: map,
    map_of_variables_to_ordered_step_ids: variableOrderedMap,
  };
};

/**
 * Iniitalizes DFS stack for "computeTracedStepIDsForBlueprint()"
 * Return stack of step IDs to visit
 */
export const initializeStack = (
  stepParameterToTrace: StepParameterTracingTarget,
  flatMapOfSteps: Record<string, BlueprintStep>,
  mapOfVariablesToOrderedStepIDs: Record<string, string[]>
) => {
  let stackStepIDs: string[] = [];

  const initialParameterValue: BlueprintParameterValue =
    flatMapOfSteps[stepParameterToTrace.step_id]["parameter_values"][
      stepParameterToTrace.parameter_value_key
    ];

  if (!initialParameterValue) return [];

  // Iniitalize the stack
  const initialStepInputData = getMappedStepsForParameterValue(
    initialParameterValue,
    stepParameterToTrace.parameter_value_key,
    flatMapOfSteps[stepParameterToTrace.step_id]
  );

  stackStepIDs = addToStepIDsStackFromStepIDs(stackStepIDs, initialStepInputData);
  stackStepIDs = addToStepIDsStackFromVariables(
    stackStepIDs,
    initialStepInputData,
    mapOfVariablesToOrderedStepIDs,
    stepParameterToTrace.step_id
  );

  // Double-check that we're not re-visiting the target step
  // Could happen for "Set Variable" steps
  stackStepIDs = stackStepIDs.filter((stepID) => stepID !== stepParameterToTrace.step_id);

  return stackStepIDs;
};
