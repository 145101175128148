import { Text, Typeahead } from "@merge-api/merge-javascript-shared";
import { JSXElementConstructor, ReactElement } from "react";

interface Props {
  loading?: boolean;
  title?: string | ReactElement;
  placeholder?: string;
  onChange: (
    _:
      | React.SyntheticEvent<Element, Event>
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element>
      | null,
    option: any
  ) => void;
  value: any;
  options: any[];
  renderOption?: (option: any) => ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  filterOptions?: (options: any[], state: any) => any[];
  getOptionLabel?: (option: any) => string;
}

const LeftPanelTypeahead = ({
  loading = false,
  title,
  placeholder,
  onChange,
  value,
  options,
  renderOption,
  filterOptions,
  getOptionLabel,
}: Props) => {
  return (
    <div className="flex flex-col space-y-2">
      {typeof title === "string" ? <Text variant="h6">{title}</Text> : title}
      <Typeahead
        loading={loading}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={options}
        renderOption={renderOption}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
      />
    </div>
  );
};

export default LeftPanelTypeahead;
