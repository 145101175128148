import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import RequestCustomerLinkedAccountDialog from "../../dialogs/RequestCustomerLinkedAccountDialog";
import CustomerLinkedAccountAccess from "./CustomerLinkedAccountAccess";
import { LinkedAccountAccessRequestStatus } from "../../../../models/Entities";
import CustomerLinkedAccountAccessRequest from "./CustomerLinkedAccountAccessRequest";
import CustomerLinkedAccountContextProvider from "../../context/CustomerLinkedAccountAccessContextProvider";
import { useCustomerLinkedAccountContext } from "../../context/CustomerLinkedAccountAccessContext";
import RevokeAccessSessionDialog from "../../dialogs/RevokeAccessSessionDialog";
import WithdrawAccessRequestDialog from "../../dialogs/WithdrawAccessRequestDialog";

interface CustomerLinkedAccountAccessSessionProps {
  integrationID: string;
  fetchTestLinkedAccounts: () => void;
}

const CustomerLinkedAccountSection = () => {
  const {
    currentAccessRequest,
    currentAccessSession,
    handleRequestButton,
    isUnauthorizedUser,
    isAccessRequestOutsideCurrentIntegration,
    isAccessSessionOutsideCurrentIntegration,
  } = useCustomerLinkedAccountContext();

  if (isUnauthorizedUser) {
    return null;
  }

  // If the current access session exists and the access request is either null or the result is acknowledged, show the access session
  if (
    currentAccessSession &&
    (!currentAccessRequest || currentAccessRequest.is_result_acknowledged) &&
    !isAccessSessionOutsideCurrentIntegration
  ) {
    return <CustomerLinkedAccountAccess accessSession={currentAccessSession} />;
  }

  // If the current access requests exists and the result is not acknowledged and the status is not withdrawn, show the access request
  if (
    currentAccessRequest &&
    !currentAccessRequest.is_result_acknowledged &&
    currentAccessRequest.status !== LinkedAccountAccessRequestStatus.WITHDRAWN &&
    !isAccessRequestOutsideCurrentIntegration
  ) {
    return <CustomerLinkedAccountAccessRequest accessRequest={currentAccessRequest} />;
  }

  // Otherwise allow the user to request a customer linked account
  return (
    <Button variant={ButtonVariant.TextBlue} onClick={handleRequestButton}>
      Request customer Linked Account
    </Button>
  );
};

const CustomerLinkedAccountSectionWithContext = ({
  integrationID,
  fetchTestLinkedAccounts,
}: CustomerLinkedAccountAccessSessionProps) => {
  return (
    <CustomerLinkedAccountContextProvider
      integrationID={integrationID}
      fetchTestLinkedAccounts={fetchTestLinkedAccounts}
    >
      <CustomerLinkedAccountSection />
      <RequestCustomerLinkedAccountDialog />
      <RevokeAccessSessionDialog />
      <WithdrawAccessRequestDialog />
    </CustomerLinkedAccountContextProvider>
  );
};

export default CustomerLinkedAccountSectionWithContext;
