import { useHistory } from "react-router-dom";
import { useState } from "react";
import { CategoryBadge } from "../../shared/MergeBadges";
import { Table, Card } from "react-bootstrap";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { ChevronToggle } from "../../shared/MergeToggles";
import { MappingTestSuiteMeta } from "../../../models/MappingTests";
import { navigateToIndividualTestSuite } from "../../../router/RouterUtils";
import styled from "styled-components";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import useDeleteTestSuite from "./hooks/useDeleteTestSuite";

type Props = {
  testSuites: MappingTestSuiteMeta[];
  integrationID: string;
  setTestSuites: React.Dispatch<React.SetStateAction<MappingTestSuiteMeta[] | undefined>>;
};

const TrashButton = styled.div`
  border: none;
  font-size: 14px;
  width: 35px;
  margin-right: 25px;
  align-content: center;
`;

const MappingTestSuitesTable = ({ testSuites, setTestSuites, integrationID }: Props) => {
  const history = useHistory();
  const [
    isShowingConfirmDeleteMappingTestModal,
    setIsShowingConfirmDeleteMappingTestModal,
  ] = useState<boolean>(false);
  const [testSuiteIDToDelete, setTestSuiteIDToDelete] = useState<string | null>(null);

  const { deleteTestSuite, isLoadingDelete } = useDeleteTestSuite({
    integrationID,
    setTestSuites,
    onClose: () => setIsShowingConfirmDeleteMappingTestModal(false),
  });

  return (
    <>
      <Dialog
        title="Delete Mapping Test Suite"
        primaryButtonVariant={ButtonVariant.DangerFilled}
        primaryButtonText="Delete"
        onPrimaryButtonClick={() => {
          if (testSuiteIDToDelete) {
            deleteTestSuite(testSuiteIDToDelete);
          }
        }}
        primaryButtonLoading={isLoadingDelete}
        open={isShowingConfirmDeleteMappingTestModal}
        onClose={() => setIsShowingConfirmDeleteMappingTestModal(false)}
        variant="sm"
      >
        This action cannot be undone
      </Dialog>

      <Card>
        <Table responsive hover>
          <thead className="table-borderless">
            <tr>
              <th scope="col">Linked Account End User Name</th>
              <th scope="col">Linked Account ID</th>
              <th scope="col">Category</th>
              <th scope="col">Coverage</th>
              <th scope="col" className="text-right" />
            </tr>
          </thead>
          <tbody>
            {testSuites ? (
              testSuites.length > 0 ? (
                <>
                  {testSuites.map((testSuite: MappingTestSuiteMeta) => (
                    <>
                      <tr
                        key={testSuite.id}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                          navigateToIndividualTestSuite(
                            history,
                            integrationID,
                            testSuite.id,
                            event.metaKey || event.ctrlKey
                          )
                        }
                        className="table-link"
                      >
                        <td className="w-25">{testSuite.end_user_organization_name}</td>
                        <td className="w-35">{testSuite.linked_account_id}</td>
                        <td className="w-10">
                          <CategoryBadge category={testSuite.category} />
                        </td>
                        <td className="w-5" />
                        <td className="text-right">
                          <div className="d-flex align-items-center">
                            <TrashButton
                              onClick={(event) => {
                                event.stopPropagation();
                                setTestSuiteIDToDelete(testSuite.id);
                                setIsShowingConfirmDeleteMappingTestModal(true);
                              }}
                            >
                              <i className="fe fe-trash-2" />
                            </TrashButton>
                            <ChevronToggle eventKey="0">{""}</ChevronToggle>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              ) : (
                <tr className="unmapped-common-models">
                  <td colSpan={8} className="p-0">
                    <EmptyStateWrapper isTable title="No Tests" />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={7} className="p-0">
                  <EmptyStateWrapper isTable isSpinner />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default MappingTestSuitesTable;
