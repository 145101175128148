import { Dialog, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  open: boolean;
  onClose: () => void;
  onPrimaryButtonClick: () => void;
}

const DiscardChangesModal = ({ open, onClose, onPrimaryButtonClick }: Props) => {
  return (
    <Dialog
      title="Unsaved payload changes"
      open={open}
      onClose={onClose}
      primaryButtonText="Discard changes"
      onPrimaryButtonClick={onPrimaryButtonClick}
    >
      <Text>You have unsaved changes in your payload. Are you sure you don’t want to save?</Text>
    </Dialog>
  );
};

export default DiscardChangesModal;
