import { BlueprintStep } from "../../../../models/Blueprints";

/**
 * Clean up tracedStepIDs to re-order based on Blueprint step ordering
 */
export const cleanUpTracedStepIDs = (
  steps: BlueprintStep[],
  tracedStepIDSet: Set<string>
): string[] => {
  let orderedTracedStepIDs: string[] = [];
  steps.forEach((step) => {
    if (tracedStepIDSet.has(step.id)) {
      orderedTracedStepIDs.push(step.id);
    }
    if (step.paths) {
      Object.values(step.paths).forEach((pathSteps) => {
        const childSteps = cleanUpTracedStepIDs(pathSteps, tracedStepIDSet);
        orderedTracedStepIDs = orderedTracedStepIDs.concat(childSteps);
      });
    }
  });
  return orderedTracedStepIDs;
};
