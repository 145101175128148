import { Typeahead } from "react-bootstrap-typeahead";
import { MinusCircle } from "lucide-react";
import FormField from "../blueprint-editor/right-panel/FormField";
import { convertTraversalPathToDotNotation } from "../blueprint-editor/utils/BlueprintEditorUtils";
import ClickableContainer from "../shared/ClickableContainer";
import useScraperContext from "./context/useScraperContext";
import { ScraperStep, ScraperValue, ScraperValueType } from "./types";
import {
  getAvailableParametersForScraperTypeahead,
  getNameForScraperValueType,
} from "./utils/ScraperUtils";

type Props = {
  valueKey: string;
  value: ScraperValue;
  updateValue: (value: ScraperValue | null) => void;
  onDelete?: () => void;
  onInputChange?: (input: string) => void;
};

const ScraperTypeaheadFormField = ({
  updateValue,
  valueKey,
  value,
  onDelete,
  onInputChange,
}: Props) => {
  const { scraper, selectedStep } = useScraperContext();
  const currentStep = selectedStep as ScraperStep;
  const availableParameters = getAvailableParametersForScraperTypeahead(
    scraper,
    currentStep,
    value
  );

  const selectedValue = value
    ? [
        {
          parameterValue: value,
          is_custom_option: value.value_type === ScraperValueType.CONSTANT,
          labelKey:
            value.value_type === ScraperValueType.CONSTANT
              ? value.constant?.toString() ?? ""
              : value.value_type === ScraperValueType.GLOBAL_VARIABLE
              ? value.global_variable_key ??
                convertTraversalPathToDotNotation(value?.request_return_value_path ?? [])
              : value.value_type === ScraperValueType.RETURN_VALUE
              ? convertTraversalPathToDotNotation([value.step_id, ...value.traversal_path])
              : value.value_type === ScraperValueType.VARIABLE
              ? value.key
              : value.value_type === ScraperValueType.NONE
              ? "None"
              : valueKey,
        },
      ]
    : [];

  return (
    <FormField title={valueKey} valueType={"any"}>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <Typeahead
            allowNew
            selected={selectedValue}
            options={availableParameters}
            clearButton={true}
            labelKey={"labelKey"}
            id="typeahead"
            inputProps={{ autoComplete: "none" }}
            newSelectionPrefix={"Set as constant: "}
            placeholder={"Input Value"}
            renderMenuItemChildren={(availableParameter: any, _, index) => {
              return (
                <div>
                  {index > 0 ? <hr /> : ""}
                  <b>{availableParameter.labelKey}</b>
                  <br />
                  {getNameForScraperValueType(availableParameter.parameterValue.value_type)}
                </div>
              );
            }}
            onChange={(options: any[]) => {
              if (!options?.[0]) {
                updateValue(null);
              } else {
                const { customOption, labelKey, parameterValue } = options[0];
                if (customOption) {
                  updateValue({
                    constant: labelKey,
                    value_type: ScraperValueType.CONSTANT,
                  });
                } else {
                  updateValue(parameterValue);
                }
              }
            }}
            onInputChange={onInputChange}
          />
        </div>
        {onDelete && (
          <div className="ml-1.5">
            <ClickableContainer onClick={onDelete}>
              <MinusCircle className="red" strokeWidth={1.5} size={16} />
            </ClickableContainer>
          </div>
        )}
      </div>
    </FormField>
  );
};

export default ScraperTypeaheadFormField;
