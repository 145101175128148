import InfiniteScroll from "react-infinite-scroll-component";
import { Integration } from "../../../../models/Entities";
import MergeTable from "../../../shared/MergeTable";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import IntegrationsDashboardTableRow from "./IntegrationsDashboardTableRow";
import { Card } from "@merge-api/merge-javascript-shared";

type IntegrationsDashboardTableProps = {
  integrations: Integration[] | undefined;
  onNext: () => void;
  hasNext: boolean;
  isLoading: boolean;
};

const IntegrationsDashboardTable = ({
  integrations = [],
  onNext,
  hasNext,
  isLoading,
}: IntegrationsDashboardTableProps) => {
  return (
    <Card className="p-0">
      <InfiniteScroll
        next={onNext}
        loader={null}
        hasMore={hasNext}
        dataLength={integrations.length}
      >
        <MergeTable
          hover
          hasMarginBottom={false}
          hasMorePaddingOnFirstElement
          header={
            <>
              <th scope="col">Name</th>
              <th scope="col">Categories</th>
              <th scope="col">Last modified</th>
              <th scope="col">Linked Accounts</th>
              <th scope="col">Status</th>
              <th scope="col">Test coverage</th>
              <th scope="col"></th>
            </>
          }
          borderBottom="1px solid rgb(237, 242, 249)"
          content={
            isLoading && !integrations.length ? (
              <TableSkeletonLoader cols={7} rows={8} size="large" />
            ) : (
              <>
                {integrations.map((integration) => (
                  <IntegrationsDashboardTableRow key={integration.id} integration={integration} />
                ))}
                {isLoading && <TableSkeletonLoader cols={5} rows={1} size="large" />}
              </>
            )
          }
        />
      </InfiniteScroll>
    </Card>
  );
};

export default IntegrationsDashboardTable;
