import React, { useEffect, useMemo, useState } from "react";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { Blueprint, BlueprintTestPayload } from "../../../../../models/Blueprints";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import { PayloadContext } from "./PayloadContext";

interface PayloadsProviderProps {
  children: JSX.Element;
  blueprint: Blueprint;
  blueprintTestPayloads: BlueprintTestPayload[];
  setBlueprintTestPayloads: React.Dispatch<React.SetStateAction<BlueprintTestPayload[]>>;
  selectedTestPayload: BlueprintTestPayload | undefined | null;
  setSelectedTestPayload: React.Dispatch<
    React.SetStateAction<BlueprintTestPayload | null | undefined>
  >;
  globalVarsAsString: string | undefined;
  setGlobalVarsAsString: (x: string) => void;
  setSelectedTestCommonModel: (x: string | undefined) => void;
  initializedSchemaForDeletePayload: string;
  selectedTestLinkedAccountID?: string;
  areInputParametersValid: boolean;
}

// Context provider component
const PayloadContextProvider = ({
  children,
  blueprint,
  blueprintTestPayloads,
  setBlueprintTestPayloads,
  selectedTestPayload,
  setSelectedTestPayload,
  globalVarsAsString,
  setGlobalVarsAsString,
  setSelectedTestCommonModel,
  initializedSchemaForDeletePayload,
  selectedTestLinkedAccountID,
  areInputParametersValid,
}: PayloadsProviderProps) => {
  const [allBlueprintTestPayloads, setAllBlueprintTestPayloads] = useState<BlueprintTestPayload[]>(
    []
  );
  const [isSavingAsNewPayload, setIsSavingAsNewPayload] = useState(false);
  const [canSavePayloadText, setCanSavePayloadText] = useState<boolean>(false);
  const [isLoadingDeletion, setIsLoadingDeletion] = useState<boolean>(false);
  const [isShowingConfirmDeletePayloadModal, setIsShowingConfirmDeletePayloadModal] = useState<
    boolean
  >(false);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [isShowingSavePayloadModal, setIsShowingSavePayloadModal] = useState<boolean>(false);
  const [isLoadingGeneratedPayload, setIsLoadingGeneratedPayload] = useState<boolean>(false);
  const [newGeneratedPayloadId, setNewGeneratedPayloadId] = useState<string | null>(null);

  // Load the selected test payload finds the payload with the given id and sets it as the selected payload along with the global vars
  const loadSelectedTestPayload = (id: string) => {
    let payload = blueprintTestPayloads.find((payload) => payload.id === id);
    setSelectedTestPayload(payload);
    if (payload) {
      setGlobalVarsAsString(payload.text);
      setSelectedTestCommonModel(payload.common_model_object_id);
    }
  };

  // Boolean isSelectedTestPayloadGenerated to determine if the selected payload is in the generated payloads array
  const [isSelectedTestPayloadGenerated, setIsSelectedTestPayloadGenerated] = useState<boolean>(
    false
  );

  // Boolean hasModifiedPayload to determine if the selected payload has text and the text is different from the global vars
  const hasModifiedPayload = useMemo<boolean>(() => {
    return (
      (!!selectedTestPayload?.text && globalVarsAsString !== selectedTestPayload?.text) ||
      isSelectedTestPayloadGenerated
    );
  }, [globalVarsAsString, selectedTestPayload, isSelectedTestPayloadGenerated]);

  // Update canSavePayloadText whenever hasModifiedPayload or isSelectedTestPayloadGenerated changes
  // You can save the payload text if it has been modified or if the selected payload is generated
  useEffect(() => {
    setCanSavePayloadText(hasModifiedPayload || isSelectedTestPayloadGenerated);
  }, [hasModifiedPayload, isSelectedTestPayloadGenerated]);

  // If the selected payload changes to a nullish value, set the global vars to the initialized schema
  useEffect(() => {
    if (!selectedTestPayload) {
      setGlobalVarsAsString(initializedSchemaForDeletePayload);
    }
  }, [selectedTestPayload]);

  // Whenever a new payload is generated, load it
  useEffect(() => {
    if (newGeneratedPayloadId) {
      loadSelectedTestPayload(newGeneratedPayloadId);
    }
  }, [newGeneratedPayloadId]);

  // Delete the payload
  const deletePayload = (payload: BlueprintTestPayload) => {
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/test-payloads`,
      method: "DELETE",
      body: {
        name: payload.name,
      },
      onResponse: () => {
        setIsLoadingDeletion(false);
        showSuccessToast("Payload deleted successfully!");
        // remove payload from blueprints array
        setBlueprintTestPayloads((prevPayloads: BlueprintTestPayload[]) =>
          prevPayloads.filter((curr) => curr.id !== payload.id)
        );
        setSelectedTestPayload(undefined);
        setGlobalVarsAsString(initializedSchemaForDeletePayload);
        setIsShowingConfirmDeletePayloadModal(false);
      },
      onError: () => {
        setIsLoadingDeletion(false);
        showErrorToast("Failed to delete test payload");
        setIsShowingConfirmDeletePayloadModal(false);
      },
    });
  };

  // Generate a write payload
  const generateWritePayload = (
    common_model_object_id: string | null,
    linked_account_id: string | null
  ) => {
    setIsLoadingGeneratedPayload(true);
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/generate-write-payload`,
      method: "POST",
      body: {
        common_model_object_id: common_model_object_id,
        linked_account_id: linked_account_id,
        blueprint_parameter_schema: blueprint.parameter_schema,
        blueprint_version_id: blueprint.version.id,
      },
      onResponse: (response: any) => {
        // parses response text and converts back to JSON object with proper formatting
        setSelectedTestPayload(null);
        const parsedResponse = JSON.parse(response.text);
        const formattedText = JSON.stringify(parsedResponse, null, 2);
        setGlobalVarsAsString(formattedText);
        setIsSelectedTestPayloadGenerated(true);
        setIsLoadingGeneratedPayload(false);
      },
      onError: () => {
        showErrorToast("Failed to generate payload");
        setIsLoadingGeneratedPayload(false);
      },
    });
  };

  // Update or add a payload
  // If the payload is new or we're saving it as a new one, append it to the array. Otherwise, update the existing payload.
  // This should be used in conjunction with API calls to save the payload.
  const updateOrAddPayload = (
    existing: BlueprintTestPayload | undefined,
    response: BlueprintTestPayload,
    isSavingAsNewPayload: boolean = false
  ) => {
    if (!existing || isSavingAsNewPayload) {
      // If we're adding a new payload, just add it to the front of the array.
      setBlueprintTestPayloads((prev) => [response, ...prev]);
      setSelectedTestPayload(response);
    } else {
      // Update the text field for the existing payload and re-insert it
      // into the payload array.
      let updatedExisting = {
        ...existing,
        name: response.name,
        linked_account_id: response.linked_account_id,
        text: response.text,
        common_model_object_id: response.common_model_object_id,
      };
      setSelectedTestPayload(updatedExisting);
      setBlueprintTestPayloads((prev) =>
        prev.map((entry) => (entry.id === existing.id ? updatedExisting : entry))
      );
    }
  };

  // Upsert a payload
  // Patch or post the payload to the API and update the state with the response.
  const upsertPayload = (
    payloadName: string,
    selectedLinkedAccountID: string | undefined,
    selectedTestCommonModel: string | undefined,
    onHide: () => void = () => {},
    isSavingAsNewPayload: boolean = false
  ) => {
    setIsLoadingSave(true);
    let existingPayload = blueprintTestPayloads.find(
      (payload) => payload.id === selectedTestPayload?.id
    );
    let method =
      existingPayload && !isSavingAsNewPayload && !isSelectedTestPayloadGenerated
        ? "PATCH"
        : "POST";
    let body = {
      ...(!isSavingAsNewPayload && { id: selectedTestPayload?.id }),
      name: payloadName,
      text: globalVarsAsString,
      linked_account_id: selectedLinkedAccountID,
      common_model_object_id: selectedTestCommonModel,
    };
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/test-payloads`,
      body: body,
      method: method,
      onResponse: (response: BlueprintTestPayload) => {
        setIsSelectedTestPayloadGenerated(false);
        showSuccessToast("Payload saved successfully!");
        updateOrAddPayload(existingPayload, response, isSavingAsNewPayload);
        setIsLoadingSave(false);
        onHide();
      },
      onError: (err) => {
        if (err) {
          err.text().then((errorMessage) => {
            showErrorToast(errorMessage);
          });
        } else {
          showErrorToast("Failed to save payload.");
        }
        setIsLoadingSave(false);
      },
    });
  };

  const updatedParameterSchema = blueprint.updated_parameter_schema_for_auto_update;

  const hasInputParameters = !!(
    Object.keys(blueprint.parameter_schema?.properties ?? {}).length > 0 ||
    blueprint.scraper ||
    updatedParameterSchema
  );

  const fetchSavedPayloads = () => {
    if (!hasInputParameters) return;
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/test-payloads`,
      method: "GET",
      onResponse: (response: BlueprintTestPayload[]) => {
        setAllBlueprintTestPayloads(response);
        setBlueprintTestPayloads(
          response.filter(
            (payload) =>
              payload.linked_account_id === selectedTestLinkedAccountID ||
              !payload.linked_account_id
          )
        );
      },
      onError: () => {
        showErrorToast("Failed to fetch test payloads.");
      },
    });
  };

  useEffect(() => {
    fetchSavedPayloads();
  }, [selectedTestLinkedAccountID]);

  return (
    <PayloadContext.Provider
      value={{
        blueprint,
        deletePayload,
        isLoadingDeletion,
        setIsLoadingDeletion,
        isShowingConfirmDeletePayloadModal,
        setIsShowingConfirmDeletePayloadModal,
        isShowingSavePayloadModal,
        setIsShowingSavePayloadModal,
        selectedTestPayload,
        setSelectedTestPayload,
        isLoadingSave,
        setIsLoadingSave,
        globalVarsAsString,
        setGlobalVarsAsString,
        isLoadingGeneratedPayload,
        setIsLoadingGeneratedPayload,
        newGeneratedPayloadId,
        setNewGeneratedPayloadId,
        generateWritePayload,
        blueprintTestPayloads,
        setBlueprintTestPayloads,
        allBlueprintTestPayloads,
        setAllBlueprintTestPayloads,
        loadSelectedTestPayload,
        fetchSavedPayloads,
        upsertPayload,
        isSelectedTestPayloadGenerated,
        hasModifiedPayload,
        canSavePayloadText,
        hasInputParameters,
        isSavingAsNewPayload,
        setIsSavingAsNewPayload,
        areInputParametersValid,
      }}
    >
      {children}
    </PayloadContext.Provider>
  );
};

export default PayloadContextProvider;
