import { useEffect, useState } from "react";
import useBlueprintContext from "../context/useBlueprintContext";
import {
  BlueprintGhostStepType,
  BlueprintStepType,
  BlueprintStepTemplate,
  BlueprintStep,
  BlueprintOperationType,
  BlueprintGenericStepTemplate,
} from "../../../models/Blueprints";
import DropdownFormField from "./DropdownFormField";
import { Copy, Save, Clipboard } from "lucide-react";
import SearchableDropdown from "./SearchableDropdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type SelectableBlueprintStepType = Array<
  Exclude<BlueprintStepType, BlueprintStepType.APIRequestLoop>
>;

type GenericStepChoice = {
  id: string;
  label: string;
  value: {
    stepType: string;
    stepImg: string;
    helptext: string;
    isGeneric: boolean;
  };
};

const BlueprintEditorRightPanelNewStepForm = () => {
  const {
    addStep,
    addGenericStep,
    addCopiedStep,
    blueprint,
    copiedStep,
    stepTemplates,
    genericStepTemplates,
    selectedStep,
    copiedSteps,
    addCopiedSteps,
    addCopiedStepsFromClipboard,
  } = useBlueprintContext();
  const step = selectedStep as BlueprintGhostStepType;
  const [stepType, setStepType] = useState<null | BlueprintStepType>(null);
  const [genericStepType, setGenericStepType] = useState<null | string>(null);
  const [
    genericStepTypesWithMoreThanOneTemplate,
    setGenericStepTypesWithMoreThanOneTemplate,
  ] = useState<Set<string>>(new Set());
  const [distinctGenericStepChoices, setDistinctGenericStepChoices] = useState<GenericStepChoice[]>(
    []
  );
  const [stepTemplate, setStepTemplate] = useState<null | BlueprintStepTemplate>(null);
  const [
    genericStepTemplate,
    setGenericStepTemplate,
  ] = useState<null | BlueprintGenericStepTemplate>(null);
  const { pathKey, relatedStepID, newStepRelation } = step;

  useEffect(() => {
    if (stepType && !isStepTypeWithMoreThanOneTemplate(stepType)) {
      setStepTemplate(
        stepTemplates.find((stepTemplate) => stepType === stepTemplate.step_type) ?? null
      );
    }
  }, [stepType, stepTemplates]);
  useEffect(() => {
    if (genericStepType && !genericStepTypesWithMoreThanOneTemplate.has(genericStepType)) {
      setGenericStepTemplate(
        genericStepTemplates.find((stepTemplate) => genericStepType === stepTemplate.step_type) ??
          null
      );
    }
  }, [genericStepType, genericStepTemplate]);

  useEffect(() => {
    if (genericStepTemplates) {
      var seen = new Set<string>();
      var duplicates = new Set<string>();
      var distinctChoices = [];
      for (const genericTemplate of genericStepTemplates) {
        if (seen.has(genericTemplate.step_type)) {
          duplicates.add(genericTemplate.step_type);
        } else {
          seen.add(genericTemplate.step_type);
          distinctChoices.push({
            id: genericTemplate.name,
            label: genericTemplate.name,
            value: {
              stepType: genericTemplate.step_type,
              stepImg: genericTemplate.image,
              helptext: genericTemplate.name,
              isGeneric: true,
            },
          });
        }
      }
      setGenericStepTypesWithMoreThanOneTemplate(duplicates);
      setDistinctGenericStepChoices(distinctChoices);
    }
  }, [genericStepTemplates]);

  const fixedStepChoices = (Object.values(BlueprintStepType).filter(
    (step_type) =>
      step_type !== BlueprintStepType.APIRequestLoop &&
      !(
        blueprint.operation_type !== BlueprintOperationType.CREATE &&
        blueprint.operation_type !== BlueprintOperationType.FUNCTIONAL &&
        step_type === BlueprintStepType.InitializeWrittenCommonModel
      ) &&
      !(
        blueprint.operation_type !== BlueprintOperationType.PROXY &&
        step_type === BlueprintStepType.APIRequestProxy
      ) &&
      !(
        blueprint.operation_type !== BlueprintOperationType.LIVE_REQUEST &&
        blueprint.operation_type !== BlueprintOperationType.LIVE_SEARCH &&
        blueprint.operation_type !== BlueprintOperationType.LIVE_RESYNC &&
        step_type === BlueprintStepType.APIRequestLive
      )
  ) as SelectableBlueprintStepType).map((stepType) => {
    switch (stepType) {
      case BlueprintStepType.APIRequest:
        return {
          id: "API Request",
          label: "API Request",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Call the API endpoint you made",
            isGeneric: false,
          },
        };
      case BlueprintStepType.APIRequestProxy:
        return {
          id: "API Request Proxy",
          label: "API Request Proxy",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "API endpoint whose response is passthrough'ed.",
            isGeneric: false,
          },
        };
      case BlueprintStepType.APIRequestLive:
        return {
          id: "API Request Live",
          label: "API Request Live",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "API endpoint that is a live request.",
            isGeneric: false,
          },
        };
      case BlueprintStepType.ArrayLoop:
        return {
          id: "Array Loop",
          label: "Array Loop",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Loop through an array",
            isGeneric: false,
          },
        };
      case BlueprintStepType.WhileLoop:
        return {
          id: "While Loop",
          label: "While Loop",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Perform loop to a variable and constraints",
            isGeneric: false,
          },
        };
      case BlueprintStepType.DateRangeLoop:
        return {
          id: "Date Range Loop",
          label: "Date Range Loop",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Loop with start & end date",
            isGeneric: false,
          },
        };
      case BlueprintStepType.IfElse:
        return {
          id: "If Else",
          label: "If Else",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add if-else logic",
            isGeneric: false,
          },
        };
      case BlueprintStepType.AddParamToEndpoint:
        return {
          id: "Add Param to Endpoint",
          label: "Add Param to Endpoint",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Adds a header, body, or query parameter to request.",
            isGeneric: false,
          },
        };
      case BlueprintStepType.CallFunctionalBP:
        return {
          id: "Call Functional BP",
          label: "Call Functional BP",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Calls a functional BP defined for this integration.",
            isGeneric: false,
          },
        };
      case BlueprintStepType.ReturnValuesToParentBP:
        return {
          id: "Return Values to Parent BP",
          label: "Return Values to Parent BP",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Return Values to the Parent BP that called this BP.",
            isGeneric: false,
          },
        };
      case BlueprintStepType.CreateOrUpdate:
        return {
          id: "Create Or Update",
          label: "Create Or Update",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Create/Update a Merge model",
            isGeneric: false,
          },
        };
      case BlueprintStepType.UpdateByModelID:
        return {
          id: "Update By Model ID",
          label: "Update By Model ID",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Update a Merge model by its Merge ID",
            isGeneric: false,
          },
        };
      case BlueprintStepType.InitializeWrittenCommonModel:
        return {
          id: "Initialize Written Common Model",
          label: "Initialize Written Common Model",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Initialize the common model object for a CREATE blueprint",
            isGeneric: false,
          },
        };
      case BlueprintStepType.ManyToManyOverride:
        return {
          id: "Many to Many Override",
          label: "Many to Many Override",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Override many to many relation on a Merge model",
            isGeneric: false,
          },
        };
      case BlueprintStepType.SetRemoteDeleted:
        return {
          id: "Set Remote Deleted",
          label: "Set Remote Deleted",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Set remote deleted",
            isGeneric: false,
          },
        };
      case BlueprintStepType.GetOrCreateByRemoteId:
        return {
          id: "Get Or Create By Remote Id",
          label: "Get Or Create By Remote Id",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Get/Create a Merge model",
            isGeneric: false,
          },
        };
      case BlueprintStepType.GetById:
        return {
          id: "Get By Id",
          label: "Get By Id",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Get a Merge model by Id",
            isGeneric: false,
          },
        };
      case BlueprintStepType.GetRelationById:
        return {
          id: "Get Relation By Id",
          label: "Get Relation By Id",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Get a Merge model's relation by Id",
            isGeneric: false,
          },
        };
      case BlueprintStepType.CommonModelLoop:
        return {
          id: "Common Model Loop",
          label: "Common Model Loop",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Loop through existing models",
            isGeneric: false,
          },
        };
      case BlueprintStepType.TraverseTree:
        return {
          id: "Traverse Tree",
          label: "Traverse Tree",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Traverse Tree Nodes with DFS",
            isGeneric: false,
          },
        };
      case BlueprintStepType.Switch:
        return {
          id: "Switch",
          label: "Switch",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add switch logic",
            isGeneric: false,
          },
        };
      case BlueprintStepType.CustomFunction:
        return {
          id: "Custom Function",
          label: "Custom Function",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add Python code",
            isGeneric: false,
          },
        };
      case BlueprintStepType.DataTransform:
        return {
          id: "Data Transform",
          label: "[TO BE DEPRECATED] Data Transform",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Use AddToDictionary/AddToArray steps instead",
            isGeneric: false,
          },
        };
      case BlueprintStepType.AddToArray:
        return {
          id: "Add to Array",
          label: "Add to Array",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add to array",
            isGeneric: false,
          },
        };
      case BlueprintStepType.AddToDictionary:
        return {
          id: "Add to Dictionary",
          label: "Add to Dictionary",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add to dictionary",
            isGeneric: false,
          },
        };
      case BlueprintStepType.SetVariable:
        return {
          id: "Set Variable",
          label: "Set Variable",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Define a variable to add into later",
            isGeneric: false,
          },
        };
      case BlueprintStepType.UpdateLinkedAccount:
        return {
          id: "Update Linked Account",
          label: "Update Linked Account",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Update linked account",
            isGeneric: false,
          },
        };
      case BlueprintStepType.ParseFromRemoteData:
        return {
          id: "Parse from Remote Data",
          label: "Parse from Remote Data",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Parse from remote data",
            isGeneric: false,
          },
        };
      case BlueprintStepType.GetLinkedAccountFields:
        return {
          id: "Get Linked Account Fields",
          label: "Get Linked Account Fields",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Get linked account fields",
            isGeneric: false,
          },
        };
      case BlueprintStepType.Assert:
        return {
          id: "Assert",
          label: "Assert",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Throw exception, stop BP",
            isGeneric: false,
          },
        };
      case BlueprintStepType.RunBlueprint:
        return {
          id: "Run Blueprint",
          label: "Run Blueprint",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Run blueprint",
            isGeneric: false,
          },
        };
      case BlueprintStepType.FileToUrl:
        return {
          id: "File to URL",
          label: "File to URL",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Convert file to url",
            isGeneric: false,
          },
        };
      case BlueprintStepType.RaiseException:
        return {
          id: "Raise Exception",
          label: "Raise Exception",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Raise an expection",
            isGeneric: false,
          },
        };
      case BlueprintStepType.EndBlueprint:
        return {
          id: "End Blueprint",
          label: "End Blueprint",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Terminates the blueprint with 200 code.",
            isGeneric: false,
          },
        };
      case BlueprintStepType.GetFirstInList:
        return {
          id: "Get First In List",
          label: "Get First In List",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Gets the first value of an object list.",
            isGeneric: false,
          },
        };
      case BlueprintStepType.FileUrlToFile:
        return {
          id: "Download File Url",
          label: "Download File Url",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Download file url",
            isGeneric: false,
          },
        };
      case BlueprintStepType.AddAvailableCustomField:
        return {
          id: "Add Available Custom Fields For Model",
          label: "Add Available Custom Fields For Model",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add custom fields",
            isGeneric: false,
          },
        };
      case BlueprintStepType.ConcurrentRequestLoop:
        return {
          id: "Run API Request Loop Concurrently",
          label: "Run API Request Loop Concurrently",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Run loop with saving time",
            isGeneric: false,
          },
        };
      case BlueprintStepType.FetchCustomFieldMapping:
        return {
          id: "Fetch Custom Field Mapping",
          label: "Fetch Custom Field Mapping",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Fetch custom field mapping",
            isGeneric: false,
          },
        };
      case BlueprintStepType.MetaAccessParentBPParamNames:
        return {
          id: "Meta: Access Parent BP Param Names",
          label: "Meta: Access Parent BP Param Names",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Access parent bp",
            isGeneric: false,
          },
        };
      case BlueprintStepType.MetaAddEnumChoiceToField:
        return {
          id: "Meta: Add Enum Choice to Field",
          label: "Meta: Add Enum Choice to Field",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add enum choice to field",
            isGeneric: false,
          },
        };
      case BlueprintStepType.MetaAddLinkedAccountParam:
        return {
          id: "Meta: Add Linked Account Param",
          label: "Meta: Add Linked Account Param",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add linked account param",
            isGeneric: false,
          },
        };
      case BlueprintStepType.AddValidationProblem:
        return {
          id: "Add Validation Problem",
          label: "Add Validation Problem",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Add validation problem",
            isGeneric: false,
          },
        };
      case BlueprintStepType.AddLinkedAccountAdditionalAuth:
        return {
          id: "Add Additional Auth",
          label: "Add Additional Auth",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "For if existing auth does not work",
            isGeneric: false,
          },
        };
      case BlueprintStepType.GetModifiedSinceTimestampValue:
        return {
          id: "Get Modified Since Timestamp Value",
          label: "Get Modified Since Timestamp Value",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "For pulling last modified data",
            isGeneric: false,
          },
        };
      case BlueprintStepType.CreateOAuth1Signature:
        return {
          id: "Create OAuth1 Signature",
          label: "Create OAuth1 Signature",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Create OAuth1 Signature",
            isGeneric: false,
          },
        };
      case BlueprintStepType.BatchAPIRespnse:
        return {
          id: "Batch API response",
          label: "Batch API response",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Batch API response",
            isGeneric: false,
          },
        };
      case BlueprintStepType.CreateQBXMLQuery:
        return {
          id: "Create QBXML Query",
          label: "Create QBXML Query",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Create a QBXML query",
            isGeneric: false,
          },
        };
      case BlueprintStepType.MergeObjects:
        return {
          id: "Merge Objects",
          label: "Merge Objects",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Merge Objects",
            isGeneric: false,
          },
        };
      case BlueprintStepType.WriteFile:
        return {
          id: "Write File",
          label: "Write File",
          value: {
            stepType: stepType,
            stepImg: stepTemplate ? stepTemplate.image : "",
            helptext: "Write data to File",
            isGeneric: false,
          },
        };
    }
  });

  const isStepTypeWithMoreThanOneTemplate = (stepType: BlueprintStepType) => {
    switch (stepType) {
      case BlueprintStepType.APIRequest:
      case BlueprintStepType.APIRequestLoop:
      case BlueprintStepType.APIRequestProxy:
      case BlueprintStepType.APIRequestLive:
      case BlueprintStepType.CreateOrUpdate:
      case BlueprintStepType.UpdateByModelID:
      case BlueprintStepType.InitializeWrittenCommonModel:
      case BlueprintStepType.SetRemoteDeleted:
      case BlueprintStepType.GetOrCreateByRemoteId:
      case BlueprintStepType.GetById:
      case BlueprintStepType.GetRelationById:
      case BlueprintStepType.CommonModelLoop:
      case BlueprintStepType.RunBlueprint:
      case BlueprintStepType.ConcurrentRequestLoop:
      case BlueprintStepType.AddAvailableCustomField:
      case BlueprintStepType.FetchCustomFieldMapping:
      case BlueprintStepType.CallFunctionalBP:
      case BlueprintStepType.ManyToManyOverride:
      case BlueprintStepType.CreateQBXMLQuery:
        return true;
      case BlueprintStepType.ReturnValuesToParentBP:
      case BlueprintStepType.CustomFunction:
      case BlueprintStepType.AddParamToEndpoint:
      case BlueprintStepType.Switch:
      case BlueprintStepType.ArrayLoop:
      case BlueprintStepType.WhileLoop:
      case BlueprintStepType.DateRangeLoop:
      case BlueprintStepType.TraverseTree:
      case BlueprintStepType.IfElse:
      case BlueprintStepType.DataTransform:
      case BlueprintStepType.AddToArray:
      case BlueprintStepType.AddToDictionary:
      case BlueprintStepType.SetVariable:
      case BlueprintStepType.UpdateLinkedAccount:
      case BlueprintStepType.ParseFromRemoteData:
      case BlueprintStepType.GetLinkedAccountFields:
      case BlueprintStepType.Assert:
      case BlueprintStepType.FileToUrl:
      case BlueprintStepType.RaiseException:
      case BlueprintStepType.EndBlueprint:
      case BlueprintStepType.GetFirstInList:
      case BlueprintStepType.FileUrlToFile:
      case BlueprintStepType.MetaAccessParentBPParamNames:
      case BlueprintStepType.MetaAddEnumChoiceToField:
      case BlueprintStepType.MetaAddLinkedAccountParam:
      case BlueprintStepType.AddValidationProblem:
      case BlueprintStepType.AddLinkedAccountAdditionalAuth:
      case BlueprintStepType.GetModifiedSinceTimestampValue:
      case BlueprintStepType.CreateOAuth1Signature:
      case BlueprintStepType.BatchAPIRespnse:
      case BlueprintStepType.MergeObjects:
      case BlueprintStepType.WriteFile:
        return false;
    }
  };

  return (
    <div className="px-3 flex-grow-1">
      <SearchableDropdown
        currentValue={stepType}
        onChange={(e) => {
          if (e.length > 0) {
            if (e[0].value.isGeneric) {
              setGenericStepType(e[0].value.stepType);
            } else {
              setStepType(e[0].value.stepType);
            }
          } else {
            setStepType(null);
          }
        }}
        placeholder="Select a step type"
        title="Step Type"
        choices={[...fixedStepChoices, ...distinctGenericStepChoices]}
      />
      {stepType && isStepTypeWithMoreThanOneTemplate(stepType) && (
        <DropdownFormField
          currentValue={stepTemplate?.id}
          onChange={(e) => {
            setStepTemplate(
              stepTemplates.find((stepTemplate) => e.target.value === stepTemplate.id) ?? null
            );
          }}
          title="Step Template"
          placeholder="Select a step template"
          choices={stepTemplates
            .filter(
              (stepTemplate) =>
                stepTemplate.step_type === stepType ||
                (stepTemplate.step_type === BlueprintStepType.APIRequestLoop &&
                  stepType === BlueprintStepType.APIRequest)
            )
            .map((template) => {
              return template;
            })}
        />
      )}
      {genericStepType && genericStepTypesWithMoreThanOneTemplate.has(genericStepType) && (
        <DropdownFormField
          currentValue={genericStepTemplate?.id}
          onChange={(e) => {
            setGenericStepTemplate(
              genericStepTemplates.find(
                (genericStepTemplate) => e.target.value === genericStepTemplate.id
              ) ?? null
            );
          }}
          title="Step Template"
          placeholder="Select a step template"
          choices={genericStepTemplates
            .filter((genericStepTemplate) => genericStepTemplate.step_type === genericStepType)
            .map((template) => {
              const suffix = template.metadata.common_model
                ? ` ${template.metadata.common_model.category}.${template.metadata.common_model.name}`
                : "";
              return {
                ...template,
                name: `${template.name}${suffix}`,
              };
            })}
        />
      )}
      <button
        disabled={stepTemplate === null && genericStepTemplate === null}
        className="btn btn-primary btn-block"
        value="Add Step"
        onClick={() => {
          if (stepTemplate) {
            addStep(stepTemplate, newStepRelation, relatedStepID, pathKey);
          } else if (genericStepTemplate) {
            addGenericStep(genericStepTemplate, newStepRelation, relatedStepID, pathKey);
          }
        }}
      >
        <Save className="mr-1.5" /> Add Step
      </button>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="custom-function-info-tooltip-cmd-shift-v">{"CMD+SHIFT+V"}</Tooltip>}
      >
        <button
          className="btn btn-primary btn-block"
          value="Add Step(s) From Clipboard"
          onClick={() => {
            addCopiedStepsFromClipboard(newStepRelation, relatedStepID, pathKey);
          }}
        >
          <Clipboard className="mr-1.5" /> Add Steps From Clipboard
        </button>
      </OverlayTrigger>
      {copiedStep && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="custom-function-info-tooltip-cmd-v">{"CMD+V"}</Tooltip>}
        >
          <button
            className="btn btn-primary btn-block"
            value="Add Copied Step"
            onClick={() => {
              addCopiedStep(copiedStep, newStepRelation, relatedStepID, pathKey);
            }}
          >
            <Copy className="mr-1.5" /> Add copy of {copiedStep.id}
          </button>
        </OverlayTrigger>
      )}
      {copiedSteps && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="custom-function-info-tooltip-cmd-v-multi">{"CMD+V"}</Tooltip>}
        >
          <button
            className="btn btn-primary btn-block"
            value="Add Copied Steps"
            onClick={() => {
              addCopiedSteps(
                copiedSteps as BlueprintStep[],
                newStepRelation,
                relatedStepID,
                pathKey
              );
            }}
          >
            <Copy className="mr-1.5" /> Add copies of{" "}
            {copiedSteps.map((step) => step.id).join(", ")}
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default BlueprintEditorRightPanelNewStepForm;
