import { Row } from "../../../shared/postman-table/PostmanTableRow";
import HeadersHelperAccordion from "../shared/HeadersHelperAccordion";
import PostmanTableHeader from "../shared/PostmanTableHeader";

interface BasicAuthNoTokenExchangeProps {
  basicAuthKeyHeaderFormat: Row[];
  setBasicAuthKeyHeaderFormat: React.Dispatch<React.SetStateAction<Row[]>>;
  className?: string;
  showHelp?: boolean;
  subtitle?: string;
}

const BasicAuthNoTokenExchange = ({
  basicAuthKeyHeaderFormat,
  setBasicAuthKeyHeaderFormat,
  className,
  showHelp = true,
  subtitle = "If an integration requires a specific auth key header format to be appended to every request, enter it below",
}: BasicAuthNoTokenExchangeProps) => {
  return (
    <>
      <PostmanTableHeader
        dataTestID="field-auth-config-basic-auth-header-fields"
        title="Header fields"
        className={className}
        subtitle={subtitle}
        rows={basicAuthKeyHeaderFormat}
        setRows={setBasicAuthKeyHeaderFormat}
      />
      {showHelp && <HeadersHelperAccordion />}
    </>
  );
};
export default BasicAuthNoTokenExchange;
