import {
  BlueprintParameterValue,
  BlueprintParameterValueType,
  BlueprintStep,
  BlueprintStepType,
} from "../../../../models/Blueprints";
import { mappedStepInputParameterData, upsertMappedStepInputParameterData } from "./SharedUtils";

/**
 * Helper function to process child parameter values for getMappedStepsForParameterValue
 */
const getMappedStepsForChildParameterValue = (
  data: mappedStepInputParameterData,
  value: BlueprintParameterValue
): mappedStepInputParameterData => {
  data = upsertMappedStepInputParameterData(data, getMappedStepsForParameterValue(value));
  return data;
};

/**
 * Extracts the mapped step ids and variables for a given parameter value
 * Example: Employee.remote_id is mapped to "get_bamboo_hr_api.items.id" --> "step_ids" = {get_bamboo_hr_api,}
 */
export const getMappedStepsForParameterValue = (
  value: BlueprintParameterValue,
  key?: string,
  step?: BlueprintStep
): mappedStepInputParameterData => {
  let data: mappedStepInputParameterData = { step_ids: new Set(), variables: new Set() };
  // Process parameter values that reference another step or variable
  if (value?.hasOwnProperty("value_type")) {
    switch (value["value_type"]) {
      case BlueprintParameterValueType.returnValue: {
        if (value?.hasOwnProperty("step_id")) {
          data.step_ids.add(value["step_id"]);
        }
        break;
      }
      // Consider edge case of "Set Variable" step's "key" parameter
      // While the "key" parameter is of type "CONSTANT", it's actually a reference to a "VARIABLE"
      case BlueprintParameterValueType.constant: {
        const isSetVariableKeyParameter =
          key === "key" &&
          (step?.template.step_type as BlueprintStepType) === BlueprintStepType.SetVariable;
        if (isSetVariableKeyParameter && value?.hasOwnProperty("constant")) {
          data.variables.add(value["constant"]);
        }
        break;
      }
      case BlueprintParameterValueType.variable: {
        if (value?.hasOwnProperty("key")) {
          data.variables.add(value["key"]);
        }
        break;
      }
      case BlueprintParameterValueType.nestedParameterValues: {
        if (value?.hasOwnProperty("nested_parameter_values")) {
          Object.values(value["nested_parameter_values"]).forEach((childParameterValue) => {
            data = getMappedStepsForChildParameterValue(data, childParameterValue);
          });
        }
        break;
      }
      case BlueprintParameterValueType.customObject: {
        if (value?.hasOwnProperty("object_value")) {
          Object.values(value["object_value"]).forEach((childParameterValue) => {
            data = getMappedStepsForChildParameterValue(data, childParameterValue);
          });
        }
        break;
      }
      case BlueprintParameterValueType.customArray: {
        if (value?.hasOwnProperty("array_values")) {
          (value["array_values"] ?? []).forEach((childParameterValue) => {
            data = getMappedStepsForChildParameterValue(data, childParameterValue);
          });
        }
        break;
      }
      case BlueprintParameterValueType.procedureArray: {
        if (value?.hasOwnProperty("procedure_array")) {
          (value["procedure_array"] ?? []).forEach((procedure) => {
            Object.values(procedure.parameter_values).forEach((childParameterValue) => {
              if (childParameterValue) {
                data = getMappedStepsForChildParameterValue(data, childParameterValue);
              }
            });
          });
        }
        break;
      }
      case BlueprintParameterValueType.statementArray: {
        if (value.hasOwnProperty("statement_array")) {
          (value["statement_array"] ?? []).forEach((statement) => {
            if (statement.val1) {
              data = getMappedStepsForChildParameterValue(data, statement.val1);
            }
            if (statement.val2) {
              data = getMappedStepsForChildParameterValue(data, statement.val2);
            }
          });
        }
        break;
      }
      default:
        break;
    }
  }
  return data;
};
