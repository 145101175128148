import { useMemo } from "react";
import {
  BlueprintParameterValueType,
  BlueprintStep,
  BlueprintParameterValueCustomFunctionType,
  BlueprintParameterValueNestedParameterValues,
  BlueprintParameterValue,
  StepLoggingViewEnum,
  StepParameterTracingTarget,
} from "../../../models/Blueprints";
import FormField, { FormFieldCommonProps } from "./FormField";
import useBlueprintContext from "../context/useBlueprintContext";
import {
  getCurrentStepParameterValue,
  getUpdatedStepParameterValueForCustomFunctionChange,
  PARAMETER_TYPES,
} from "../utils/BlueprintEditorUtils";
import CollapsibleChoiceMappingForm from "./CollapsibleChoiceMappingForm";
import Dropdown from "./Dropdown";
import TypeaheadFormFieldTypeahead from "./TypeaheadFormFieldTypeahead";
import { Accordion, Col, Row, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { SettingsButtonToggle } from "../../shared/MergeToggles";
import { HeaderPretitle } from "../../shared/text/MergeText";
import { firstLetterUpperCase } from "../../../utils";
import DeprecatedH5 from "../../deprecated/DeprecatedH5";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";

interface Props extends FormFieldCommonProps {
  valueKey: string;
  includeUniqueIdentifierCheck?: boolean;
  parameterType: string;
  disabled?: boolean;
  validator?: (x: string, y: BlueprintParameterValue) => boolean;
  choices_override?: Array<string>;
  choiceNamesOverride?: Array<string>;
}

const TypeaheadFormField = <T extends BlueprintStep>({
  valueKey,
  parameterType,
  includeUniqueIdentifierCheck = true,
  subtitle,
  title,
  disabled,
  validator,
  choices_override,
  choiceNamesOverride,
}: Props) => {
  const {
    selectedStep,
    updateStepParameterValue,
    stepLoggingView,
    blueprintRunnerExecutionResponse,
    isTracing,
    setIsTracing,
    stepParameterToTrace,
    setStepParameterToTrace,
    computeTracedStepIDs,
    isFlagEnabledForTracing,
    setTracedStepIDs,
  } = useBlueprintContext();
  const step = selectedStep as T;
  const isUniqueIdentifier = step.parameter_values?.[valueKey]?.is_unique_identifier;
  const currentParameterValue = getCurrentStepParameterValue(step, valueKey);
  const currentParameterValueType = currentParameterValue?.value_type;
  const deprecated = step.template.parameter_schema?.[valueKey]?.deprecated;
  const choices =
    choices_override ??
    step.template.parameter_schema?.[valueKey]?.enum ??
    // @ts-ignore choices is deprecated
    step.template.parameter_schema?.[valueKey]?.choices;
  const choiceNames = choiceNamesOverride ?? undefined;

  const choiceMapping =
    currentParameterValue && "choice_mapping" in currentParameterValue
      ? currentParameterValue?.choice_mapping
      : undefined;
  const customFunction =
    currentParameterValue && "custom_function" in currentParameterValue
      ? currentParameterValue?.custom_function
      : undefined;

  const paramReturnSchema = (selectedStep as BlueprintStep).template.return_schema;
  const paramProps = paramReturnSchema.properties
    ? paramReturnSchema.properties[valueKey]
    : undefined;

  const isCoverageMissing = useMemo(() => {
    const parameterValueCoverage =
      blueprintRunnerExecutionResponse?.exit_data?.coverage?.step_coverage?.[step.id]
        ?.parameter_value_coverage?.[valueKey];
    if (!parameterValueCoverage) {
      return false;
    }
    return (
      parameterValueCoverage.is_parameter_used_in_blueprint &&
      !parameterValueCoverage.was_non_null_value_used
    );
  }, [
    blueprintRunnerExecutionResponse?.exit_data?.coverage?.step_coverage?.[step.id]
      ?.parameter_value_coverage?.[valueKey],
  ]);

  function getValueType() {
    let paramValueType: string =
      paramProps?.format === PARAMETER_TYPES.UUID ? PARAMETER_TYPES.UUID : parameterType;
    if (
      Array.isArray(choices) &&
      choices.length === 0 &&
      (!paramValueType || (Array.isArray(paramValueType) && paramValueType.length === 0))
    ) {
      // Handle edge case for enums without any choices or type specified.
      // Default to string.
      paramValueType = PARAMETER_TYPES.STRING;
    }
    if (paramValueType) {
      switch (paramValueType) {
        case PARAMETER_TYPES.UUID:
          return `${paramProps?.relation?.model || ""} UUID`;
        case PARAMETER_TYPES.URL:
          return "URL";
        default:
          return firstLetterUpperCase(paramValueType);
      }
    }
    return undefined;
  }
  const valueType = getValueType();

  /* --- START - TRACING FUNCTIONS --- */

  // Trigger tracing for the specified parameter_value key
  const handleTracing = () => {
    setIsTracing(true);
    const newStepParameterToTrace: StepParameterTracingTarget = {
      step_id: step.id,
      parameter_value_key: valueKey,
    };
    setStepParameterToTrace(newStepParameterToTrace);
    computeTracedStepIDs(newStepParameterToTrace);
  };

  // Trigger for stopping tracing
  const handleStopTracing = () => {
    setIsTracing(false);
    setStepParameterToTrace(undefined);
    setTracedStepIDs([]);
  };

  const isTracedParameter = useMemo(
    () =>
      step.id == stepParameterToTrace?.step_id &&
      valueKey == stepParameterToTrace?.parameter_value_key,
    [stepParameterToTrace]
  );

  const hasAppliedEffectOnFormField = useMemo(
    () =>
      (stepLoggingView === StepLoggingViewEnum.STEP_COVERAGE && isCoverageMissing) ||
      (isTracing && isTracedParameter),
    [stepLoggingView, isCoverageMissing, isTracing, isTracedParameter]
  );

  const showTraceButton = useMemo(
    () => isFlagEnabledForTracing && !isTracedParameter && currentParameterValue,
    [isFlagEnabledForTracing, isTracedParameter, currentParameterValue]
  );

  const showStopTracingButton = useMemo(() => isFlagEnabledForTracing && isTracedParameter, [
    isFlagEnabledForTracing,
    isTracedParameter,
  ]);

  /* --- END - TRACING FUNCTIONS --- */

  return (
    <div>
      <FormField
        className={clsx(
          hasAppliedEffectOnFormField && "-m-2 p-2 rounded-lg",
          isTracing && isTracedParameter && "shadow-[0px_0px_0px_1px] shadow-blue-40",
          stepLoggingView === StepLoggingViewEnum.STEP_COVERAGE &&
            isCoverageMissing &&
            "bg-yellow-0"
        )}
        subtitle={subtitle}
        title={title}
        valueType={valueType}
        deprecated={deprecated}
        choices={choices}
        relationModel={paramProps?.relation?.model}
        titleRightChildren={
          showTraceButton ? (
            <Button variant={ButtonVariant.TextBlue} size="sm" onClick={handleTracing}>
              Trace
            </Button>
          ) : showStopTracingButton ? (
            <Button variant={ButtonVariant.TextBlue} size="sm" onClick={handleStopTracing}>
              Stop tracing
            </Button>
          ) : undefined
        }
      >
        <Accordion className="row">
          <Col>
            <Row>
              <Col>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    {currentParameterValueType ===
                    BlueprintParameterValueType.nestedParameterValues ? (
                      <>
                        {Object.entries(
                          (currentParameterValue as BlueprintParameterValueNestedParameterValues)
                            .nested_parameter_values
                        ).map(([parameterValueKey, parameterValueValue]) => (
                          <>
                            <DeprecatedH5 className="mt-1.5 mb-1.5">
                              {parameterValueKey}
                            </DeprecatedH5>
                            <TypeaheadFormFieldTypeahead
                              allowConstantValues
                              disabled={disabled}
                              currentParameterValue={parameterValueValue}
                              key={parameterValueKey}
                              choices={choices}
                              choiceNames={choiceNames}
                              onChange={(options) => {
                                updateStepParameterValue(step, valueKey, {
                                  ...currentParameterValue,
                                  choice_mapping: choiceMapping,
                                  custom_function: customFunction,
                                  nested_parameter_values: {
                                    ...(currentParameterValue as BlueprintParameterValueNestedParameterValues)
                                      .nested_parameter_values,
                                    [parameterValueKey]:
                                      (options[0]?.customOption
                                        ? {
                                            constant: options[0].labelKey,
                                            value_type: BlueprintParameterValueType.constant,
                                          }
                                        : options[0]?.parameterValue) ?? null,
                                  },
                                });
                              }}
                              parameterType={
                                [valueType, parameterType].includes(PARAMETER_TYPES.UUID)
                                  ? PARAMETER_TYPES.UUID
                                  : PARAMETER_TYPES.ANY
                              }
                              parameterKey={valueKey}
                            />
                          </>
                        ))}
                      </>
                    ) : (
                      <TypeaheadFormFieldTypeahead
                        allowConstantValues
                        disabled={disabled}
                        currentParameterValue={currentParameterValue}
                        choices={choices}
                        choiceNames={choiceNames}
                        validator={(paramVal: BlueprintParameterValue) =>
                          validator ? validator(valueKey, paramVal) : true
                        }
                        onChange={(options) => {
                          const newStepValue = options[0]?.customOption
                            ? {
                                constant: options[0].labelKey,
                                value_type: BlueprintParameterValueType.constant,
                                is_unique_identifier: isUniqueIdentifier,
                              }
                            : {
                                ...options[0]?.parameterValue,
                                choice_mapping: choiceMapping,
                                custom_function: customFunction,
                                is_unique_identifier: isUniqueIdentifier,
                              };

                          updateStepParameterValue(step, valueKey, newStepValue);
                        }}
                        parameterType={
                          paramProps?.format && paramProps.format === PARAMETER_TYPES.UUID
                            ? paramProps.format
                            : parameterType === PARAMETER_TYPES.FIELD_REFERENCE
                            ? parameterType
                            : "any"
                        }
                        parameterKey={valueKey}
                      />
                    )}
                  </div>
                  <div className="ml-1.5">
                    <SettingsButtonToggle eventKey="0" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Accordion.Collapse eventKey="0">
                  <Row className="mb-9">
                    <Col>
                      <HeaderPretitle className="mt-9">
                        Custom Functions
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 0 }}
                          overlay={
                            <Tooltip id="create-or-update-custom-function-info-tooltip">
                              Combine a create or update step and a custom function by selecting one
                              of the custom functions in the dropdown below.
                            </Tooltip>
                          }
                        >
                          <i className="ml-1.5 text-muted text-right fe fe-info float-right" />
                        </OverlayTrigger>
                      </HeaderPretitle>
                      <Dropdown
                        currentValue={
                          step.parameter_values?.[valueKey]?.custom_function?.custom_function_type
                        }
                        onChange={(e) =>
                          updateStepParameterValue(
                            step,
                            valueKey,
                            getUpdatedStepParameterValueForCustomFunctionChange(
                              currentParameterValue,
                              e.target.value
                            )
                          )
                        }
                        placeholder="Select to add a custom function."
                        choices={[
                          ...Object.values(BlueprintParameterValueCustomFunctionType).map(
                            (val) => ({
                              name: val,
                              id: val,
                            })
                          ),
                          { name: "None", id: "None" },
                        ]}
                      />
                      {includeUniqueIdentifierCheck && (
                        <>
                          <HeaderPretitle className="mt-9">
                            Mark as a unique identifier
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 100, hide: 0 }}
                              overlay={
                                <Tooltip id="unique-identifier-info-tooltip">
                                  If there is no remote_id for an object, select the fields which
                                  would be a unique identifier for this common model.
                                </Tooltip>
                              }
                            >
                              <i className="ml-1.5 text-muted text-right fe fe-info float-right" />
                            </OverlayTrigger>
                          </HeaderPretitle>
                          <Form.Check
                            type="checkbox"
                            id="active"
                            onChange={() =>
                              updateStepParameterValue(step, valueKey, {
                                ...currentParameterValue,
                                is_unique_identifier: !isUniqueIdentifier,
                              })
                            }
                            checked={isUniqueIdentifier ?? false}
                            label="Is this field a unique identifier?"
                          />
                        </>
                      )}
                    </Col>
                  </Row>
                </Accordion.Collapse>
              </Col>
            </Row>
          </Col>
        </Accordion>
        {choices &&
          currentParameterValueType &&
          [
            BlueprintParameterValueType.returnValue,
            BlueprintParameterValueType.nestedParameterValues,
            BlueprintParameterValueType.inputParameter,
            BlueprintParameterValueType.globalVariable,
          ].includes(currentParameterValueType) && (
            <CollapsibleChoiceMappingForm
              choices={choices}
              choiceMapping={choiceMapping ?? {}}
              updateChoiceMapping={(choiceMapping) =>
                updateStepParameterValue(step, valueKey, {
                  ...currentParameterValue,
                  choice_mapping: choiceMapping,
                })
              }
            />
          )}
      </FormField>
    </div>
  );
};

export default TypeaheadFormField;
