import { BlueprintGenericStepTemplate } from "../../../models/Blueprints";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import TypeaheadFormField from "./TypeaheadFormField";
import { HeaderPretitle } from "../../shared/text/MergeText";

interface Props {
  stepID: string;
  stepTemplate: BlueprintGenericStepTemplate;
}

// Define a map for custom parameter ordering per step type
const CUSTOM_PARAMETER_ORDERS: Record<string, string[]> = {
  STEP_TYPE_WRITE_GLOBAL_VARIABLE: ["variable_label", "variable_key", "variable_value"],
  STEP_TYPE_READ_GLOBAL_VARIABLE: ["variable_label", "variable_key"],
};

const BlueprintEditorRightPanelGenericStepForm = ({ stepID, stepTemplate }: Props) => {
  const parameters = Object.entries(stepTemplate.parameter_schema?.properties ?? {});

  return (
    <>
      <form>
        <HeaderPretitle className="my-2">{"Parameter Values"}</HeaderPretitle>
        {(Object.keys(CUSTOM_PARAMETER_ORDERS).includes(stepTemplate.step_type)
          ? parameters.sort(([keyA], [keyB]) => {
              const customOrder = CUSTOM_PARAMETER_ORDERS[stepTemplate.step_type];
              if (!customOrder) return keyA.localeCompare(keyB); // Fallback if no custom order

              const indexA = customOrder.indexOf(keyA);
              const indexB = customOrder.indexOf(keyB);

              if (indexA === -1 && indexB === -1) return keyA.localeCompare(keyB);
              if (indexA === -1) return 1;
              if (indexB === -1) return -1;
              return indexA - indexB;
            })
          : parameters.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        ) // Default alphabetical sort
          .map(([key, parameter]) => {
            return (
              <TypeaheadFormField
                key={key}
                title={parameter.title}
                subtitle={parameter.description}
                valueKey={key}
                parameterType={parameter.type}
                choices_override={parameter?.enum}
                choiceNamesOverride={parameter?.enumNames}
              />
            );
          })}
      </form>
      <JSONSchemaTreeDiagram stepID={stepID} jsonSchema={stepTemplate.return_schema} />
    </>
  );
};

export default BlueprintEditorRightPanelGenericStepForm;
